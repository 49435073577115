import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  useContext
} from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link, useNavigate, useParams, useNavigation } from "react-router-dom";

import {
  ShopIcon,
  GoBackIcon,
  HomeIcon,
  LoadingIconSimple,
  RefreshIcon,
  TopicsIcon,
  UKFlagIcon,
} from "../component/Icons";
import PictureWall from "../component/PictureWall/PictureWall";
import { getImageUrl, getLocation, getUniqueId, getUserRoleName, getUserType, slugify } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import { IAddress, IUser } from "../interface";
import {
  apiEndPoint,
  authHeadersWithoutContentType
  , userCredentials,
} from "../api/api";
import { SendVerificationCode, UpdateContact, getUser, saveUser } from "./userApi";
import Loading from "../component/Loading";
import Modal from "../component/Modal";
import { confirmAlert } from "react-confirm-alert";
import AddressEdit from "./AddressEdit";
import GoBackLink from "../component/GoBackLink";
import ManageAddress from "./ManageAddress";
import AppContext from "../component/AppContext";

const User = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { appContext, setAppContext } = useContext(AppContext);
  const UserId =
    appContext.isLoggedIn ? appContext.userID : 0;

  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [HasVerificationSent, setHasVerificationSent] = useState(false);
  const [TempData, setTempData] = useState<any>({
    "email": "",
    "phone": "",
    "code": ""
  });
  const [ChangeContext, setChangeContext] = useState<"phone" | "email">(
    "phone"
  );
  const [User, setUser] = useState<IUser>({
    id: "",
    name: "",
    username: "",
    profileImageUrl: "",
    role: 4,
  });
  const [ImageFileName, setImageFileName] = useState("");
  const [Image, setImage] = useState<Array<any>>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setUser({ ...User, [name]: value });
    console.log(User);
  };

  const handleTempDataChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;

    setTempData({ ...TempData, [name]: value });
  };

  const handleChangeKeyValue = (key: string, value: string | boolean) => {
    setUser({ ...User, [key]: value });
  };

  const sendCode = () => {
    SendVerificationCode(ChangeContext, TempData[ChangeContext]).then((result) => {
      setHasVerificationSent(true);
    }).catch((error) => {
      notify("Error occurred: " + error);
    });
  }

  const verifyCode = () => {
    UpdateContact(UserId, ChangeContext, TempData[ChangeContext], TempData["code"])
      .then((result) => {
        if (result.status == 200 || result.status == 304) {
          setIsModalVisible(false);
          toast.success("Contact has been updated successfully");
          loadUser();
        } else {
          alert(result.statusText);
          notify(result.statusText + " : Error occurred");
        }
      })
  }

  const changeContact = (contactType: "phone" | "email") => {
    setChangeContext(contactType);
    setHasVerificationSent(false);
    setIsModalVisible(true);
  };



  const handleFileUpload = (file: any) => {
    return new Promise(function (resolve, rejected) {
      var formData = new FormData();
      formData.append("img", file);
      formData.append("action", "upload");
      fetch(apiEndPoint + "/v1/images/user", {
        headers: authHeadersWithoutContentType(),
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setImageFileName(result.path);

          const mergedObject = {
            url: getImageUrl("User", result.path),
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            ...result.file,
          };
          resolve(mergedObject);
        })
        .catch((error) => {
          rejected("Upload fail : " + error);
        });
    });
  };

  const handleFileDelete = (uuid?: string) => {
    return new Promise(function (resolve, rejected) {
      if (uuid == "1") {
        resolve(1);
      }
      fetch(apiEndPoint + "/v1/images/user/" + uuid, {
        headers: authHeadersWithoutContentType(),
        method: "DELETE",
      })
        .then((result) => {
          if (result.ok || result.status == 304) {
            resolve(uuid);
          } else {
            rejected("error");
          }
        })
        .catch((error) => {
          rejected(error);
        });
    });
  };

  const loadUser = () => {
    if (IsLoading) {
      return;
    }
    setIsLoading(true);
    getUser(UserId)
      .then((result: any) => {
        if (!result.about) {
          result.about = "";
        }
        setUser(result);
        setImageFileName(result.profileImageUrl);
        let file = {
          url: getImageUrl("User", result.profileImageUrl),
          uuid: result.profileImageUrl ? result.profileImageUrl : 1,
        };
        setImage([file]);
        setImageFileName(result.profileImageUrl);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log("error");
      });
  };


  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (!formRef.current) {
      return false;
    }
    setIsSaving(true);

    saveUser({ ...User, profileImageUrl: ImageFileName })
      .then((result: any) => {
        toast.success("Changes updated successfully");
        setIsSaving(false);
        console.log(result);
        setTimeout(() => {
          console.log(result);
          navigate(`/users/${UserId}/settings`);
        }, 2000);
      })
      .catch((err: any) => {
        setIsSaving(false);
        console.log(err);
        toast.error(err.message);
      });

    return false;
  };

  useEffect(() => {
    loadUser();
    return () => { };
  }, []);

  const notify = (message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="Shop">
      <div className="page" style={{ maxWidth: "1015px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex">
            <h1>User Detail</h1>
            {IsSaving && (
              <i className="svg-icon" style={{ fontSize: "40px" }}>
                <LoadingIconSimple />
              </i>
            )}
          </div>
          <GoBackLink />
        </div>
        {IsLoading && <Loading title="Loading data" />}
        {!IsLoading && (
          <div className="card shadow-popover">
            <form
              ref={formRef}
              method="post"
              onSubmit={(e) => {
                handleSave(e);
              }}
            >
              <div className="body">
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Name</label>
                    </h3>
                  </div>
                  <div className="form-block">
                    <input
                      id="title"
                      type="text"
                      className="form-control"
                      required
                      value={User.name}
                      name="name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="description">About</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Say something about yourself.
                    </p>
                  </div>
                  <div className="form-block">
                    <textarea
                      id="description"
                      className="form-control"
                      value={User.about}
                      onChange={handleInputChange}
                      name="about"
                    // required
                    />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="description">Profile Image</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Use a square and smaller (500x500px) image for best
                      result. Format allowed .jpg, .jpeg and .png
                    </p>
                  </div>
                  <div className="form-block">
                    <PictureWall
                      handleUpload={handleFileUpload}
                      handleDelete={handleFileDelete}
                      maxCount={1}
                      notify={notify}
                      onChange={setImage}
                      fileList={Image}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Email</label>
                    </h3>
                  </div>
                  <div className="form-block">
                    <p>
                      {User.eMail != null && User.eMail + " "}
                      {User.eMail != null && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            changeContact("email");
                          }}
                        >
                          {" Change"}
                        </a>
                      )}
                      {User.eMail == null && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            changeContact("email");
                          }}
                        >
                          Add Email
                        </a>
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Contact Number</label>
                    </h3>
                  </div>
                  <div className="form-block">
                    <p>
                      {User.phone != null && User.phone}
                      {User.phone != null && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            changeContact("phone");
                          }}
                        >
                          {" Change"}
                        </a>
                      )}
                      {User.phone == null && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            changeContact("phone");
                          }}
                        >
                          Add Phone Number
                        </a>
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Addresses</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Your delivery addresses
                    </p>
                  </div>
                  <ManageAddress userId={UserId} />
                </div>

                {getUserType(appContext.role) == "Admin" && (
                  <div className="flex">
                    <div style={{ width: "400px" }}>
                      <h3 style={{ margin: "8px 0px" }}>
                        <label htmlFor="title">User Role</label>
                      </h3>
                      <p style={{ margin: "0px" }} className="muted">
                        User role
                      </p>
                    </div>
                    <div className="form-block">
                      <div>
                        {getUserRoleName(User.role)}
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex">
                  <div style={{ width: "400px" }}></div>
                  <button
                    className="btn btn-primary shadow-popover-sm"
                    type="submit"
                    disabled={IsSaving}
                  >
                    {IsSaving ? "Saving..." : "Save User"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {IsModalVisible && (
        <Modal
          title="Change Detail"
          onClose={() => {
            setIsModalVisible(!IsModalVisible);
          }}
          isVisible={IsModalVisible}
          content={
            <div className="flex" style={{ margin: "16px 0px", flexDirection: "column", alignItems: "start" }}>
              {ChangeContext == "phone" && (
                <>
                  {User.phone != "" && (
                    <p style={{ margin: "0px 0px 16px 0px", padding: "8px", background: "#fafafa" }}>
                      Current Number <b>{User.phone}</b>
                    </p>
                  )}
                  <label>
                    New Number<br />
                    <i className="svg-icon">
                      <UKFlagIcon />
                    </i>+44
                    <input type="text" name="phone" value={TempData["phone"]} onChange={handleTempDataChange} />
                  </label>
                </>
              )}
              {ChangeContext == "email" && (
                <>
                  {User.eMail != null && (
                    <p>
                      Current Email <b>{User.eMail}</b>
                    </p>
                  )}
                  <label>
                    New Email <br />
                    <input type="email" name="email" value={TempData["email"]} onChange={handleTempDataChange} />
                  </label>
                </>
              )}

              <a href="#" onClick={(e) => {
                e.preventDefault();
                sendCode();
              }} style={{ marginTop: "8px" }}>Send Verification Code</a>
              {HasVerificationSent && <>
                <label>
                  Enter Verification Code <br />
                  <input type="number" maxLength={10} name="code" value={TempData["code"]} onChange={handleTempDataChange} />
                </label>

                <button type="button" onClick={verifyCode}>Save Changes</button>
              </>}

            </div>
          }
        />
      )}
    </AdminLayout>
  );
};

export default User;
