import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";

import Confirm from "../component/Confirm";
import {
  ContentIcon,
  GoBackIcon,
  LoadingIconSimple,
  RefreshIcon,
  SettingsIcon,
  TopicsIcon,
  TrashIcon,
  ViewIcon,
} from "../component/Icons";
import Loading from "../component/Loading";
import NotFound from "../component/NotFound";
import Pagination from "../component/Pagination";
import { IUser, Item } from "../interface";

import AdminLayout from "../layouts/AdminLayout";
import UserCard from "./UserCard";
import { deleteUser, getUser, getUsers } from "./userApi";
import { userCredentials } from "../api/api";
import { toast } from "react-hot-toast";

const Users = () => {
  const navigate = useNavigate();
  const [IsSaving, setIsSaving] = useState(false);
  const [IsInitialLoading, setIsInitialLoading] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [HasError, setHasError] = useState(false);
  const PageSize = 12;
  const [NoOfPages, setNoOfPages] = useState(0);
  const [Query, setQuery] = useState("");


  const loadUsers = (pageNumber: number) => {
    console.log(userCredentials);
    setIsLoading(true);
    getUsers(pageNumber, 10, Query)
      .then((result: any) => {
        setNoOfPages(result.totalElements);
        setUsers(result.content);
        setIsInitialLoading(false);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsInitialLoading(false);
        toast.error("Unable to load " + error);
        setIsLoading(false);
      });
  };


  //delete User
  const deleteUserAct = (user: Item) => {
    setIsSaving(true);
    deleteUser(user).then((result) => {
      setIsSaving(false);
      loadUsers(CurrentPage);
      toast.success("User has been deleted successfully");
    }).catch((error) => {
      setIsSaving(false);
      toast.error("Error: " + error);
    });
  };

  const confirmDeleteUser = (user: Item) => {
    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure to DELETE the user?",
      buttons: [
        {
          label: "Delete",
          onClick: () => deleteUserAct(user),
        },
        {
          label: "No",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };

  useEffect(() => {
    loadUsers(CurrentPage);
  }, []);

  return (
    <AdminLayout searchableType={"Users"}>
      <div className="page" style={{ maxWidth: "1080px" }}>
        {IsInitialLoading && <Loading title="Loading users" />}
        {!IsInitialLoading && HasError && (
          <div>Error occured while loading users</div>
        )}
        {!IsInitialLoading && (
          <React.Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Users</h1>
                <a
                  href="#"
                  title="Refresh"
                  style={{
                    background: "rgb(213 218 226)",
                    margin: "0px 8px",
                    padding: "4px",
                    lineHeight: "18px",
                    height: "26px",
                    borderRadius: "4px"
                  }}

                  onClick={(e) => {
                    e.preventDefault();
                    if (IsLoading) {
                      return;
                    }
                    setIsSaving(true);
                    loadUsers(CurrentPage);

                  }}
                >
                  {!IsLoading &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <RefreshIcon />
                    </i>}
                  {IsLoading &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <LoadingIconSimple />
                    </i>}
                </a>
              </div>

              <Pagination
                currentPage={CurrentPage}
                noOfItems={NoOfPages}
                onPageChange={(page: number) => {
                  setIsLoading(true);
                  setCurrentPage(page);
                  loadUsers(page)
                }}
                pageSize={PageSize}
              />
            </div>

            <React.Fragment>
              {users.length === 0 && (
                <NotFound
                  title="No users found"
                  message="The REST users accessible by the authenticated Pega user will appear here."
                />
              )}
              <div className="nav">
                <div className="list">
                  {users.map((user, index) => {
                    return (
                      <UserCard user={user} key={index} confirmDeleteUser={confirmDeleteUser} />
                    )
                  })
                  }
                </div>
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </AdminLayout >
  );
};

export default Users;
