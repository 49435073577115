import React from "react";
import { IAddress } from "../interface";
import { EditIcon, PhoneIcon, TrashIcon } from "../component/Icons";

const Address = ({
  address,
  deleteFn,
  edit,
}: {
  address: IAddress;
  deleteFn: Function;
  edit: Function;
}) => {
  return (
    <address
      className="flex mt-1"
      style={{
        alignItems: "center",
        justifyContent: "space-between",
        flexGrow: 1,
        // background: "#d2d3d3",
        padding: "16px",
        borderRadius: "4px",
      }}
    >
      <div>
        {address.name}
        <br />
        {address.deliveryAddress}
        <br />
        <i className="svg-icon">
          <PhoneIcon />
        </i>
        {address.phoneNumber}
      </div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          edit();
        }}
        className="btn"
      >
        <i className="svg-icon">
          <EditIcon />
        </i>
        Edit
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          deleteFn(address);
        }}
      >
        <i className="svg-icon">
          <TrashIcon />
        </i>
        Delete
      </a>
    </address>
  );
};

export default Address;
