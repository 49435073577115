import React, { useState, useEffect, useRef } from "react";
import { LoaderIcon } from "react-hot-toast";
import { categorieStore } from "./categoryStore";
import { getImageUrl } from "../helper";
import { ItemCategory } from "../interface";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import Loading from "../component/Loading";

import useSwipe from "../component/useSwipe";

// import "./CategoryFilter.scss";
import { getCategories } from "./categoriesApi";
import { HomeIcon } from "../component/Icons";

const CategoryFilter = ({
    value,
    onChange,
}: {
    value: number;
    onChange: Function;
}) => {
    const [IsOpen, setIsOpen] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);
    // const [CategoryId, setCategoryId] = useState<number | undefined>(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const ListRef = useRef<HTMLDivElement>(null);

    const swipeHandlers = useSwipe({
        onSwipedLeft: (distance) => handleSwipe("left", distance),
        onSwipedRight: (distance) => handleSwipe("right", distance),
    });

    const handleSwipe = (direction: string, distance: number) => {
        console.log(direction, distance);
        let child = containerRef.current?.firstElementChild;

        if (child) {
            //check if  child node is div

            let childWidth = child.clientWidth;
            let containerWidth = containerRef.current?.clientWidth;
            if (containerWidth && childWidth) {
                let maxLeft = containerWidth - childWidth;
                let left = containerRef.current?.offsetLeft;
                console.log("left", left)
                if (left) {
                    if (direction == "left") {
                        console.log("left", `${left - distance}px`);
                        if (left > maxLeft) {
                            if (child instanceof HTMLDivElement)
                                child.style.left = `${left - distance}px`;
                        }
                    } else {
                        if (left < 0) {
                            if (child instanceof HTMLDivElement)

                                child.style.left = `${left + distance}px`;
                        }
                    }
                }
            }

        }
    };

    const loadCategories = () => {
        if (categorieStore.isLoaded) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        getCategories()
            .then((result: any) => {
                categorieStore.categories = result.content.map(
                    (category: ItemCategory) => {
                        category.imagePath = getImageUrl("Category", category.imagePath);
                        return category;
                    }
                );
                categorieStore.isLoaded = true;
                setIsLoading(false);
            })
            .catch((error: any) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadCategories();
        return () => { };
    }, []);

    const filteredCategories: Array<ItemCategory> =
        categorieStore.categories.filter((category: ItemCategory) => {
            return category.parentCategoryId == value;
        });

    //select category
    const selectCategory = (categoryId?: number) => {
        onChange(categoryId);
        setIsOpen(false);
    };

    return (
        <div className="category-filter">
            <div style={{ position: "relative" }} >
                {IsLoading && <LoaderIcon />}
                {!IsLoading && (
                    <>
                        <Breadcrumb
                            currentId={value}
                            items={categorieStore.categories}
                            rootId={2}
                            onSelect={(category: any) => selectCategory(category)}
                        />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                position: "relative",
                                // height: "120px",
                                // overflow: "hidden",
                            }}
                            {...swipeHandlers}
                            ref={containerRef}

                        >
                            <div
                                className="category-list"
                                style={{
                                    display: "flex",
                                    columnGap: "16px",
                                    // position: "absolute",
                                    top: "0px",
                                    left: "-0px",
                                }}
                            >
                                {filteredCategories.map((category, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="category-item"
                                            onClick={() => selectCategory(category.id)}
                                            style={{
                                                cursor: "pointer",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div className="category">
                                                <img
                                                    src={category.imagePath}
                                                    width="70px"
                                                    height="70px"
                                                />
                                                <p style={{ fontSize: "14px" }}>{category.name}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CategoryFilter;
