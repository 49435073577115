import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  useContext
} from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";

import {

  LoadingIconSimple,
  MapIcon,
  PhoneIcon,
  ToggleOnIcon,
  ToggleOffIcon,
  EditIcon,
  AddIcon,
  OrderIcon,
  UserIcon,
  ProductIcon,
} from "../component/Icons";

import { getImageUrl, getLocation } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import { IShop } from "../interface";
import {
  apiEndPoint,
  authHeadersWithoutContentType,
} from "../api/api";
import { getEmptyShop, getShop } from "./shopApi";
import { getShopRegistrationStatus } from "./constants";
import Loading from "../component/Loading";
import OverviewStatic from "../component/OverviewStatic";
import Overview from "../component/Overview";
import { getOrdersEndPoint } from "../Order/orderApi";
import GoBackLink from "../component/GoBackLink";
import AppContext from "../component/AppContext";

const ShopView = () => {
  const { appContext, setAppContext } = useContext(AppContext);
  const { shopId } = useParams();

  const [Counter, setCounter] = useState(1);
  const ShopId = shopId != undefined ? parseInt(shopId) : undefined;
  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  let emptyShop = getEmptyShop();
  const [Shop, setShop] = useState<IShop>(emptyShop);
  const [ImageFileName, setImageFileName] = useState("");
  const [Image, setImage] = useState<Array<any>>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setShop({ ...Shop, [name]: value });
  };

  const handleChangeKeyValue = (key: string, value: string | boolean) => {
    setShop({ ...Shop, [key]: value });
  };

  const setCurrentLocation = () => {
    getLocation().then((location: any) => {
      setShop({
        ...Shop,
        latCenter: location.coords.latitude,
        lonCenter: location.coords.longitude,
      });
      setCounter(Counter + 1);
    });
  };

  const setShopLocation = (location: any) => {
    console.log(location);
    setShop({ ...Shop, latCenter: location.lat, lonCenter: location.lng });
  };

  const handleFileUpload = (file: any) => {
    return new Promise(function (resolve, rejected) {
      var formData = new FormData();
      formData.append("img", file);
      formData.append("action", "upload");
      fetch(apiEndPoint + "/v1/images/shop", {
        headers: authHeadersWithoutContentType(),
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setImageFileName(result.path);
          const mergedObject = {
            url: getImageUrl("Shop", result.path),
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            ...result.file,
          };
          resolve(mergedObject);
        })
        .catch((error) => {
          rejected("Upload fail : " + error);
        });
    });
  };

  const handleFileDelete = (uuid?: string) => {
    return new Promise(function (resolve, rejected) {
      if (uuid == "1") {
        resolve(1);
      }
      fetch(apiEndPoint + "/v1/images/shop/" + uuid, {
        headers: authHeadersWithoutContentType(),
        method: "DELETE",
      })
        .then((result) => {
          if (result.ok) {
            resolve(uuid);
          } else {
            rejected("error");
          }
        })
        .catch((error) => {
          rejected(error);
        });
    });
  };

  const loadShop = () => {
    if (IsLoading) {
      return;
    }
    if (ShopId == undefined) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getShop(ShopId, "Edit")
      .then((result: any) => {
        setShop(result);
        setImageFileName(result.logoImagePath);
        let file = {
          url: getImageUrl("Shop", result.logoImagePath),
          uuid: result.logoImagePath ? result.logoImagePath : 1,
        };
        setImage([file]);
        setImageFileName(result.logoImagePath);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log("error");
      });
  };



  useEffect(() => {
    loadShop();
    return () => { };
  }, []);

  const notify = (type: string, message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="Shop">
      <div
        className="page"
        style={{
          alignItems: "start",
          maxWidth: "1600px",
          justifyContent: "start",
        }}
      >
        {IsLoading && <Loading title="Loading data" />}
        {!IsLoading && (
          <>
            <div className="flex" style={{ justifyContent: "end" }}>
              <GoBackLink />
            </div>

            <div className="flex" style={{ alignItems: "start" }}>
              <div>
                <div
                  className="card shadow-popover"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div className="flex">
                    <div
                      className="thumbnail-cover"
                      style={{ height: "100px", width: "100px" }}
                    >
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={getImageUrl("Shop", Shop.logoImagePath)}
                      />
                    </div>
                    <div style={{ margin: "8px" }}>
                      <h1 style={{ margin: "8px 0px" }}>
                        {Shop.shopName + " "}
                        <div className="tag">
                          {getShopRegistrationStatus(Shop.registrationStatus)}
                        </div>
                      </h1>
                      {IsSaving && (
                        <i className="svg-icon" style={{ fontSize: "40px" }}>
                          <LoadingIconSimple />
                        </i>
                      )}
                      <p style={{ margin: "0px" }}>
                        {Shop.shopAddress} <br />
                        {Shop.city + " " + Shop.postCode} <br />
                        <a
                          href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            Shop.latCenter +
                            "%2C" +
                            Shop.lonCenter
                          }
                          target="_new"
                        >
                          <i className="svg-icon">
                            <MapIcon /> Get Direction
                          </i>
                        </a>
                      </p>
                    </div>
                  </div>
                  <p style={{ margin: "8px 0px" }} className="muted">
                    {Shop.shortDescription}
                  </p>
                  <div className="">
                    <h3>
                      <i className="svg-icon">
                        <PhoneIcon />{" "}
                      </i>{" "}
                      {Shop.customerHelplineNumber}
                    </h3>
                  </div>
                  <div>
                    <label style={{ fontSize: "18px" }}>
                      <i
                        className="svg-icon"
                        style={{
                          verticalAlign: "middle",
                          display: "inline-block",
                          padding: "0px 4px",
                          fontSize: "24px",
                        }}
                      >
                        <ToggleOnIcon />
                      </i>
                      Home Delivery
                    </label>
                    <label style={{ fontSize: "18px", marginLeft: "8px  " }}>
                      <i
                        className="svg-icon"
                        style={{
                          verticalAlign: "middle",
                          display: "inline-block",
                          padding: "0px 4px",
                          fontSize: "24px",
                        }}
                      >
                        <ToggleOffIcon />
                      </i>
                      Store Pickup
                    </label>
                  </div>
                  <div className="actions" style={{ padding: "4px 0px" }}>
                    <Link to={"settings"} className="button bg-default">
                      <i className="svg-icon">
                        <EditIcon />
                      </i>
                    </Link>
                  </div>
                </div>
                {Shop.homeDeliveryAvailable && (
                  <div
                    className="shadow-popover card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "8px",
                      margin: "8px 8px 8px 0px",
                    }}
                  >
                    <p className="muted" style={{ margin: "8px 0px" }}>Home Delivery charges</p>
                    {Shop.deliveryHelplineNumber && (
                      <p>{Shop.deliveryHelplineNumber}</p>
                    )}
                    <p>
                      Delivery charges <b>£{Shop.deliveryCharges}</b>
                      <br /><br />
                      {"Free delivery for purchase above "}
                      <b>£{Shop.billAmountForFreeDelivery}</b>
                    </p>
                  </div>
                )}
              </div>
              <div className="overview" style={{ flexGrow: 1 }}>
                <Overview
                  title="Products"
                  dataSource={getOrdersEndPoint}
                  getValueFromData={(data: any) => {
                    return data.totalElements;
                  }}
                  icon={ProductIcon}
                  isAuthenticated={true}
                  styleClass="warning"
                  onClick={() => { }}
                />

                <Overview
                  title="Orders"
                  dataSource={getOrdersEndPoint}
                  getValueFromData={(data: any) => {
                    return data.totalElements;
                  }}
                  icon={OrderIcon}
                  isAuthenticated={true}
                  styleClass="danger"
                  onClick={() => { }}
                />

                <Overview
                  title="Customers served"
                  dataSource={getOrdersEndPoint}
                  getValueFromData={(data: any) => {
                    return data.totalElements;
                  }}
                  icon={UserIcon}
                  isAuthenticated={true}
                  styleClass="success"
                  onClick={() => { }}
                />
                <OverviewStatic
                  title="Balance"
                  onClick={() => { }}
                  styleClass="primary"
                  icon={AddIcon}
                  value={"" + (Shop.accountBalance ? Shop.accountBalance : 0)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default ShopView;
