import { Item } from "../interface";
import { apiEndPoint, authHeaders, userCredentials } from "../api/api";

// export const getProducts = (
//   pageNumber: number,
//   pageSize: number,
//   query: string | null
// ) => {
//   if (query == null) {
//     query = "";
//   }
//   let endPoint =
//     apiEndPoint +
//     "/v1/products?offset=" +
//     (pageNumber - 1) +
//     "&limit=" +
//     pageSize +
//     "&sort_by=id" +
//     "&sort_direction=desc" +
//     "&query=" +
//     query;
//   return new Promise((resolve: Function, reject: Function) => {
//     fetch(endPoint, {
//       method: "GET",
//       headers: authHeaders(),
//     })
//       .then((result) => result.json())
//       .then((json) => resolve(json))
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const getEmptyProduct = () => {
  return <Item>{
    name: "",
    itemDesc: "",
    itemDescriptionLong: "",
    itemCategoryId: 0,
    itemImageUrl: "",
    quantityUnit: "",
    listPrice: 1,
    isItemEnabled: true,
    dateTimeCreated: new Date(),
  };
};

export const getProduct = (id: number) => {
  let endPoint = apiEndPoint + "/v1/products/" + id;

  return new Promise<Item>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveProduct = (product: Item) => {
  let endPoint = apiEndPoint;
  let method = "POST";
  if (product.id) {
    method = "PUT";
  }
  endPoint = endPoint + "/v1/products";
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify(product),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProduct = (product: Item) => {
  let endPoint = apiEndPoint + "/v1/products/" + product.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          isSuccess = false;
        }
        return result.json();
      })
      .then((result: any) => {
        if (isSuccess) {
          resolve(result);
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProducts = (
  pageNumber: number,
  sort_by: string,
  sort_direction: string,
  query: string,
  category?: number
) => {
  let endPoint =
    apiEndPoint +
    "/v1/products?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    12 +
    "&sort_by=" +
    sort_by +
    "&sort_direction=" +
    sort_direction +
    "&query=" +
    query;
  if (category != 1) {
    endPoint = endPoint + "&category=" + category;
  }
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductsByCategory = (
  categoryId: number,
  pageNumber: number,
  sort_by: string,
  sort_direction: string,
  query: string
) => {
  let endPoint =
    apiEndPoint +
    "/v1/categories/" +
    categoryId +
    "/products?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    12 +
    "&sort_by=" +
    sort_by +
    "&sort_direction=" +
    sort_direction +
    "&query=" +
    query;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getInventory = (
  shopId: number,
  categoryId: number,
  pageNumber: number,
  sort_by: string,
  sort_direction: string,
  query: string
) => {
  let endPoint =
    apiEndPoint +
    "/v1/shops/" +
    shopId +
    "/products?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    12 +
    "&categoryId=" +
    categoryId +
    "&sort_by=" +
    sort_by +
    "&sort_direction=" +
    sort_direction +
    "&query=" +
    query;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

// export const saveProduct = (product: Item) => {
//   let endPoint = apiEndPoint + "/v1/product" + product;
//   return new Promise((resolve: Function, reject: Function) => {
//     fetch(endPoint, {
//       method: "POST",
//       headers: authHeaders(),
//       body: JSON.stringify(product),
//     })
//       .then((result) => {})
//       .catch((error) => {});
//   });
// };
