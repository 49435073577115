import React, { useState, useEffect } from "react";
import { LoaderIcon } from "react-hot-toast";
import { categorieStore } from "../../Category/categoryStore";
import { getImageUrl } from "../../helper";
import { ItemCategory } from "../../interface";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Loading from "../Loading";

import "./CategoryPicker.scss";
import { getCategories } from "../../Category/categoriesApi";

const CategoryPicker = ({
    value,
    onChange,
}: {
    value: number;
    onChange: Function;
}) => {
    const [IsOpen, setIsOpen] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);
    const [CategoryId, setCategoryId] = useState<number | undefined>(1);

    const loadCategories = () => {
        if (categorieStore.isLoaded) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        getCategories()
            .then((result: any) => {
                categorieStore.categories = result.content.map(
                    (category: ItemCategory) => {
                        category.imagePath = getImageUrl("Category", category.imagePath);
                        return category;
                    }
                );
                categorieStore.isLoaded = true;
                setIsLoading(false);
            })
            .catch((error: any) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadCategories();
        return () => { };
    }, []);

    const filteredCategories: Array<ItemCategory> =
        categorieStore.categories.filter((category: ItemCategory) => {
            return category.parentCategoryId == CategoryId;
        });

    //select category
    const selectCategory = (categoryId?: number) => {
        onChange(categoryId);
        setIsOpen(false);
    };

    return (
        <div className="category-picker">
            <div style={{ position: "relative" }}>
                {IsLoading && <LoaderIcon />}
                {!IsLoading && (
                    <>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsOpen(true);
                            }}
                        >
                            Change
                        </a>
                        {IsOpen && (
                            <div className="pick-box-container">
                                <div className="pick-box">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <Breadcrumb
                                                currentId={CategoryId}
                                                items={categorieStore.categories}
                                                rootId={2}
                                                onSelect={(category: any) => setCategoryId(category)}
                                            />
                                        </div>
                                        <div>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    selectCategory(CategoryId);
                                                }}
                                            >
                                                Choose Current
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsOpen(false);
                                                }}
                                                style={{ marginLeft: "8px" }}
                                            >
                                                <i className="fa-solid fa-close" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="category-list">
                                        {filteredCategories.map((category, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="category-item"
                                                    onClick={() => setCategoryId(category.id)}
                                                >
                                                    <div className="category">
                                                        <img
                                                            src={category.imagePath}
                                                            width="90px"
                                                            height="90px"
                                                        />
                                                        <p>{category.name}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CategoryPicker;
