import React, { useState, useEffect } from 'react';

import "./style.css";

const AutoComplete = ({ suggestions, handleChange, defaultValue, required }
    : { suggestions: Array<string>, defaultValue: string, handleChange: Function, required?: boolean }) => {

    const [ActiveSuggestions, setActiveSuggestions] = useState(0);
    const [FilterredSuggestions, setFilterredSuggestions] = useState<Array<string>>([]);
    const [ShowSuggestions, setShowSuggestions] = useState(false);
    const [UserInput, setUserInput] = useState(defaultValue);

    let suggestionsListComponent;


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setActiveSuggestions(0);
        setShowSuggestions(true);
        setFilterredSuggestions(filteredSuggestions);
        setUserInput(userInput);
        handleChange(userInput);

    };

    const onClick = (e: any) => {
        setActiveSuggestions(0);
        setShowSuggestions(false);
        setFilterredSuggestions([]);
        setUserInput(e.currentTarget.innerText);
        handleChange(e.currentTarget.innerText);
    };

    const onKeyDown = (e: any) => {
        // User pressed the enter key
        if (e.keyCode === 13) {
            setActiveSuggestions(0);
            setShowSuggestions(false);
            setUserInput(FilterredSuggestions[ActiveSuggestions]);
            handleChange(FilterredSuggestions[ActiveSuggestions]);
            e.preventDefault();
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (ActiveSuggestions === 0) {
                return;
            }
            setActiveSuggestions(ActiveSuggestions - 1);
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (ActiveSuggestions - 1 === FilterredSuggestions.length) {
                return;
            }

            setActiveSuggestions(ActiveSuggestions + 1);
        }
    };

    if (ShowSuggestions && UserInput) {
        if (FilterredSuggestions.length) {
            suggestionsListComponent = (
                <ul className="suggestions">
                    {FilterredSuggestions.map((suggestion, index) => {
                        let className;

                        // Flag the active suggestion with a class
                        if (index === ActiveSuggestions) {
                            className = "suggestion-active";
                        }

                        return (
                            <li className={className} key={suggestion} onClick={onClick}>
                                {suggestion}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            suggestionsListComponent = (
                <div className="no-suggestions">
                    <em>No suggestions, you're on your own!</em>
                </div>
            );
        }
    }



    return (
        <>
            <input
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={UserInput}
                required={required}
            />
            {suggestionsListComponent}
        </>
    );
};

export default AutoComplete;