import React, { createContext } from "react";

export interface appParams {
  search: string;
  categoryId: number;
  isLoggedIn: boolean;
  userID: number;
  profile: string;
  role: number;
  name: string;
  deviceToken: string;
  deviceID: string;
  isSideBarOpen: boolean;
  isInDarkMode: boolean;
  shopID?: number;
}

// define the type for our context object
interface appContextType {
  appContext: appParams;
  setAppContext: React.Dispatch<React.SetStateAction<appParams>>;
}

export const defaultAppContext: appParams = {
  search: "",
  categoryId: 1,
  role: 7,
  isLoggedIn: false,
  userID: 0,
  name: "",
  profile: "",
  deviceToken: "",
  deviceID: "",
  isSideBarOpen: false,
  isInDarkMode: false,
};

const AppContext = createContext<appContextType>({
  appContext: defaultAppContext,
  setAppContext: () => {},
});

export default AppContext;
