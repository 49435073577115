import React, { useState, useEffect, useContext } from "react";
import { userCredentials } from "../api/api";
import { ICart } from "./interface";
import { LoadingIconSimple, RefreshIcon } from "../component/Icons";
import GoBackLink from "../component/GoBackLink";
import { getCarts, getCartsFromLocalStorage } from "./api";
import NotFound from "../component/NotFound";
import AppContext from "../component/AppContext";
import AdminLayout from "../layouts/AdminLayout";

const Carts = () => {
    const [Carts, setCarts] = useState<Array<ICart>>([]);
    const { appContext, setAppContext } = useContext(AppContext);
    const [isLoading, setisLoading] = useState(true);

    //load carts
    const loadCarts = () => {
        if (appContext.isLoggedIn) {
            getCarts()
                .then((data) => {
                    setCarts(data);
                    setisLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setisLoading(false);
                });
        } else {
            getCartsFromLocalStorage()
                .then((data) => {
                    setCarts(data);
                    setisLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setCarts([]);
                    setisLoading(false);
                });
        }
    };

    useEffect(() => {
        loadCarts();
    }, [appContext.isLoggedIn]);

    return (
        <AdminLayout searchableType={"Products"}>
            <div className="page" style={{ maxWidth: "1015px" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="flex">
                        <h1>Carts</h1>
                        <a
                            href="#"
                            title="Refresh"
                            style={{
                                background: "rgb(213 218 226)",
                                margin: "0px 8px",
                                padding: "4px",
                                lineHeight: "18px",
                                height: "26px",
                                borderRadius: "4px",
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                setisLoading(true);
                                loadCarts();
                                setTimeout(() => {
                                    setisLoading(false);
                                }, 500);
                            }}
                        >
                            {!isLoading && (
                                <i className="svg-icon" style={{ fontSize: "24px" }}>
                                    <RefreshIcon />
                                </i>
                            )}
                            {isLoading && (
                                <i className="svg-icon" style={{ fontSize: "24px" }}>
                                    <LoadingIconSimple />
                                </i>
                            )}
                        </a>
                    </div>
                </div>
                {Carts.length == 0 && (
                    <NotFound
                        title="Not found"
                        message="You do not have any active shopping cart."
                    />
                )}
                {Carts.length > 0 && (
                    <div className="flex" style={{ flexWrap: "wrap" }}>
                        {Carts.map((cart, index) => {
                            return (
                                <div
                                    key={index}
                                    className="card"
                                    style={{
                                        width: "300px",
                                        margin: "8px",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
                                    }}
                                >
                                    <div className="flex" style={{ alignItems: "center" }}>
                                        <div style={{ flex: "1" }}>
                                            {/* <h3>{cart.name}</h3>
                                            <p>{cart.description}</p> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </AdminLayout>
    );
};

export default Carts;
