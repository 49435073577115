import React, { useState, useEffect, useRef, FormEvent } from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchJSONData, getCategoriesEndPoint, apiEndPoint, authHeadersWithoutContentType, } from "../api/api";
import { categorieStore } from "./categoryStore";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import CategoryPicker from "../component/CategoryPicker/CategoryPicker";
import { CategoryIcon, GoBackIcon, HomeIcon, LoadingIconSimple, RefreshIcon, TopicsIcon } from "../component/Icons";
import PictureWall from "../component/PictureWall/PictureWall";
import { getImageUrl, getUniqueId, slugify } from "../helper";
import { IFile, ItemCategory } from "../interface";
import AdminLayout from "../layouts/AdminLayout";
import { getCategories, getCategory, saveCategory } from "./categoriesApi";
import GoBackLink from "../component/GoBackLink";

const Category = () => {
  const { categoryId } = useParams();
  const isNewCategory = categoryId === undefined;
  const CategoryId = categoryId != undefined ? parseInt(categoryId) : undefined;
  const [IsSaving, setIsSaving] = useState(false);
  const [IsAllCategoriesLoaded, setIsAllCategoriesLoaded] = useState(categorieStore.isLoaded);
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [ParentCategoryId, setParentCategoryId] = useState(categorieStore.suggestedParent);
  const [SortOrder, setSortOrder] = useState(0);
  const [Image, setImage] = useState<Array<any>>([]);
  const [ImageFileName, setImageFileName] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const updateName = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleFileUpload = (file: any) => {
    return new Promise(function (resolve, rejected) {
      var formData = new FormData();
      formData.append("img", file);
      formData.append("action", "upload");
      fetch(apiEndPoint + "/v1/images/category", {
        headers: authHeadersWithoutContentType(),
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return rejected(response.statusText);
        })
        .then((result) => {
          setImageFileName(result.path);
          const mergedObject = {
            url: getImageUrl("Category", result.path),
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            ...result.file,
          }
          resolve(mergedObject);
        })
        .catch((error) => {
          rejected("Upload fail : " + error);
        });
    });
  }

  const handleFileDelete = (uuid?: string) => {
    return new Promise(function (resolve, rejected) {
      if (uuid == "1") {
        resolve(1);
      }
      fetch(apiEndPoint + "/v1/images/category/" + uuid, {
        headers: authHeadersWithoutContentType(),
        method: "DELETE"
      })
        .then((result) => {
          if (result.ok) {
            resolve(uuid);
          } else {
            if (result.status == 404) {
              return resolve(uuid);
            }
            rejected("error")
          }
        })
        .catch((error) => {
          rejected(error);
        })
    });
  };


  const loadCategories = () => {
    if (IsAllCategoriesLoaded) {
      return;
    }
    getCategories()
      .then((result: any) => {
        categorieStore.categories =
          result.content.map((category: ItemCategory) => {
            category.imagePath = getImageUrl("Category", category.imagePath);
            return category;
          })
        categorieStore.isLoaded = true;
        setIsAllCategoriesLoaded(true);
      })
      .catch((error: any) => {
        console.log("error");
      });
  };

  const loadCategory = () => {
    setIsSaving(true);
    if (categoryId == undefined) {
      return;
    }
    getCategory(parseInt(categoryId)).then((data: ItemCategory) => {
      setName(data.name);
      setDescription(data.description == null ? "" : data.description);
      setParentCategoryId(data.parentCategoryId);
      let file = {
        url: getImageUrl("Category", data.imagePath),
        uuid: data.imagePath ? data.imagePath : 1
      };
      setImage([file]);
      setSortOrder(data.categoryOrder);
      setImageFileName(data.imagePath);
      setIsSaving(false);
    }).catch((err: any) => {
      console.log(err);
      setIsSaving(false);
    });
  }


  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (!formRef.current) {
      return false;
    }
    setIsSaving(true);

    let categoryData: ItemCategory = {
      categoryOrder: SortOrder,
      id: CategoryId,
      description: Description,
      imagePath: ImageFileName,
      name: Name,
      parentCategoryId: ParentCategoryId
    };

    saveCategory(categoryData).then((result: any) => {
      toast.success("Category saved successfully");
      setIsSaving(false);
      console.log(result);
      if (isNewCategory) {
        navigate(`/categories/${result.id}/settings`);
      }
    }).catch((err: any) => {
      setIsSaving(false);
      console.log(err);
      toast.error(err.message);
    });

    return false;
  };

  useEffect(() => {
    if (!isNewCategory) {
      loadCategories();
      loadCategory();
    }
    return () => {
    }
  }, []);

  const notify = (message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="Category" >
      <div className="page" style={{ maxWidth: "1015px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div className="flex">
            <h1>Category Detail</h1>
            {IsSaving && (
              <i className="svg-icon" style={{ fontSize: "40px" }}>
                <LoadingIconSimple />
              </i>
            )}

          </div>
          <GoBackLink />


        </div>
        <div className="card shadow-popover">
          <form ref={formRef} method="post" onSubmit={(e) => { handleSave(e) }} >
            <div className="body">
              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="title">Name</label>
                  </h3>
                </div>
                <div className="form-block">
                  <input
                    id="title"
                    type="text"
                    className="form-control"
                    required
                    value={Name}
                    onChange={updateName}
                  />
                </div>
              </div>
              <div className="flex" >
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="description">Description</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    This is a short summary of the category.
                  </p>
                </div>
                <div className="form-block">
                  <textarea
                    id="description"
                    className="form-control"
                    value={Description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  // required
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="title">Parent Category</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    Choose the parent category id
                  </p>
                </div>
                <div className="form-block">
                  {IsAllCategoriesLoaded && <>
                    <Breadcrumb items={categorieStore.categories} onSelect={setParentCategoryId} rootId={1} currentId={ParentCategoryId} />
                    <CategoryPicker onChange={setParentCategoryId} value={ParentCategoryId} />
                  </>}
                  {!IsAllCategoriesLoaded && <LoaderIcon />}
                </div>
              </div>
              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="title">Sort Order</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    Category display priority. Lowest displayed first.
                  </p>
                </div>
                <div className="form-block">
                  <input
                    id="title"
                    type="number"
                    className="form-control"
                    required
                    value={SortOrder}
                    onChange={(e) => {
                      setSortOrder(parseInt(e.target.value));
                    }}
                  />
                </div>
              </div>
              <div className="flex" >
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="description">Image</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    Use a square and smaller (500x500px) image  for best result.
                    Format allowed .jpg, .jpeg and .png
                  </p>
                </div>
                <div className="form-block">
                  <PictureWall handleUpload={handleFileUpload}
                    handleDelete={handleFileDelete} maxCount={1} notify={notify} onChange={setImage} fileList={Image} />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: "400px" }}>
                </div>
                <button className="btn btn-primary shadow-popover-sm" type="submit" disabled={IsSaving}>
                  {IsSaving ? "Saving..." : "Save Category"}
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </AdminLayout >
  );
};

export default Category;
