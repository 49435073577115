import { Item, ItemCategory } from "../interface";

export const categorieStore: ItemCategoryStore = {
  isLoaded: false,
  categories: [],
  suggestedParent: 1,
};

export interface ItemCategoryStore {
  isLoaded: boolean;
  categories: Array<ItemCategory>;
  suggestedParent: number;
}
