export const shopStatus = {
  New: 1,
  Enabled: 2,
  Waitlisted: 3,
  Banned: 4,
};

export const getShopRegistrationStatus = (status: number | undefined) => {
  switch (status) {
    case undefined:
    case 1:
      return "New";
      break;
    case 2:
      return "Enabled";
      break;
    case 3:
      return "Waitlisted";
      break;
    case 4:
      return "Banned";
      break;
    default:
      break;
  }
};

export const getShopRegistrationStyle = (status: number | undefined) => {
  switch (status) {
    case undefined:
    case 1:
      return "default";
      break;
    case 2:
      return "success";
      break;
    case 3:
      return "warning";
      break;
    case 4:
      return "danger";
      break;
    default:
      break;
  }
};

export const shopSubscription = {
  Basic: 1,
  Standard: 2,
  Premium: 3,
  1: "Basic",
  2: "Standard",
  3: "Premium",
};
