import React from 'react';
import { IShop, Item } from '../interface';
import { SettingsIcon, TopicsIcon, TrashIcon, ViewIcon } from '../component/Icons';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../helper';

const ShopCard = ({ shop, confirmDeleteShop }: { shop: IShop, confirmDeleteShop: Function }) => {
    return (
        <div
            className="card shadow-popover-sm"
            style={{
                padding: "6px",
                marginBottom: "32px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                maxWidth: "300px",
            }}
        >
            <div
                style={{ borderRadius: "6px", whiteSpace: "normal" }}
                className="shadow-embed flex"
            >
                <img
                    src={getImageUrl("Shop", shop.logoImagePath)}
                    style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "6px",
                    }}
                />
                <div className="heading" style={{ padding: "6px", whiteSpace: "normal" }}>
                    <h3>{shop.name}</h3>
                    <p>#{shop.id}</p>
                </div>
            </div>
            <br />
            <div className="actions">
                <Link to={"/shops/" + shop.id}>
                    <ViewIcon />
                </Link>

                <Link
                    to={"/shops/" + shop.id + "/settings"}
                    className="button button-big bg-default"
                >
                    <i className="svg-icon">
                        <SettingsIcon />
                    </i>
                </Link>

                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        confirmDeleteShop(shop);
                    }}
                    className="button button-big bg-danger"
                >
                    <i className="svg-icon">
                        <TrashIcon />
                    </i>
                </a>
            </div>
        </div>);
};

export default ShopCard;