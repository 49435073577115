import React from 'react';
import { Item } from '../interface';
import { SearchIcon, SettingsIcon, ShopIcon, TopicsIcon, TrashIcon, ViewIcon } from '../component/Icons';
import { Link } from 'react-router-dom';
import { getImageUrl, getUserRoleName, getUserType } from '../helper';
import AppContext from '../component/AppContext';

const ProductCard = ({ product, confirmDeleteProduct }: { product: Item, confirmDeleteProduct: Function }) => {
    const { appContext, setAppContext } = React.useContext(AppContext);
    const cursor = getUserType(appContext.role) == "User" ? "pointer" : "default ";
    return (
        <div
            className="card shadow-popover-sm"
            style={{
                padding: "6px",
                marginBottom: "32px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: cursor,
            }}
            onClick={(e) => {
                e.preventDefault();
                if (getUserType(appContext.role) == "User") {
                    window.location.href = "/products/" + product.id;
                }
            }}

        >
            <div
                style={{ borderRadius: "6px", whiteSpace: "normal" }}
                className="shadow-embed flex"
            >
                <img
                    src={getImageUrl("Item", product.itemImageUrl)}
                    style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "6px",
                    }}
                />
                <div className="heading" style={{ padding: "6px", whiteSpace: "normal" }}>
                    <h3>{product.name}</h3>
                    {product.itemDescriptionLong && <p>{product.itemDescriptionLong}</p>}
                </div>


            </div>


            <div className="actions">
                <a
                    href={"/products/" + product.id}
                    className="button button-big bg-default"
                >
                    <ViewIcon />
                </a>
                {getUserType(appContext.role) !== "User" && <>
                    <Link
                        to={"/products/" + product.id + "/settings"}
                        className="button button-big bg-default"
                    >
                        <i className="svg-icon">
                            <SettingsIcon />
                        </i>
                    </Link>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            confirmDeleteProduct(product);
                        }}
                        className="button button-big bg-danger"
                    >
                        <i className="svg-icon">
                            <TrashIcon />
                        </i>
                    </a>
                </>}
            </div>
        </div>);
};

export default ProductCard;