import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate, useParams } from "react-router-dom";

import { categorieStore } from "./categoryStore";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import {
  AddIcon,
  EditIcon,
  LoadingIconSimple,
  RefreshIcon,
  TrashIcon,
} from "../component/Icons";
import Loading from "../component/Loading";
import NotFound from "../component/NotFound";
import { getImageUrl, listToTree } from "../helper";
import { Item, ItemCategory } from "../interface";

import AdminLayout from "../layouts/AdminLayout";
import { toast } from "react-hot-toast";
import ProductCard from "../Product/ProductCard";
import { getProductsByCategory } from "../Product/productApi";
import { deleteCategory, getCategories, getSubCategories } from "./categoriesApi";

const Categories = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const categoryId = parseInt(id == undefined ? "1" : id);
  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [CurrentCategories, setCurrentCategories] = useState<Array<ItemCategory>>([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [HasError, setHasError] = useState(false);
  const [Products, setProducts] = useState<Array<Item>>([]);
  const [IsLeafCategory, setIsLeafCategory] = useState(false);
  const PageSize = 50;

  const loadCategories = (isSoft: boolean) => {
    if (categorieStore.isLoaded == true) {
      resetCategories();
      return;
    }
    if (!isSoft) {
      setIsLoading(true);
    }
    getCategories()
      .then((result: any) => {
        categorieStore.categories =
          result.content.map((category: ItemCategory) => {
            if (category.imagePath != "") {
              category.imagePath = getImageUrl("Category", category.imagePath);
            } else {
              category.imagePath = "https://placehold.co/500?text=No+Image+Set&font=roboto"
            }
            return category;
          })
          ;
        categorieStore.isLoaded = true;
        resetCategories();
        setIsLoading(false);
      })
      .catch((error: any) => {
        toast.error(error);
        setIsLoading(false);
      });
  };

  const resetCategories = () => {
    let filterredCategories = categorieStore.categories.filter((category: ItemCategory) => {
      return category.parentCategoryId == categoryId;
    }).sort(sortCategory);
    setCurrentCategories(filterredCategories);
    categorieStore.suggestedParent = categoryId;
    if (filterredCategories.length == 0) {
      loadProducts(categoryId);
    }
  }

  const loadProducts = (categoryId: number) => {
    console.log("sub categories", categoryId, getSubCategories(categoryId));
    setIsLoading(true);
    getProductsByCategory(categoryId, 1, "", "", "").then((result: any) => {

      setProducts(result.content);
      setIsLoading(false);
    }).catch((error) => {
      toast.error(error);
    })
  }

  //delete Category
  const handleDelete = (category: ItemCategory) => {
    setIsSaving(true);
    deleteCategory(category).then((data: any) => {
      categorieStore.isLoaded = false;
      toast.success("Category has been deleted successfully");
      setIsSaving(false);
      loadCategories(true);
    }).catch((err) => {
      toast.error(err);
      console.log(err);
      setIsSaving(false);
    });
  };

  const confirmDeleteCategory = (category: ItemCategory) => {
    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure to DELETE the category?",
      buttons: [
        {
          label: "Delete",
          onClick: () => handleDelete(category),
        },
        {
          label: "No",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };


  useEffect(() => {
    loadCategories(false);

    return () => {
    }
  }, [id]);


  const sortCategory = (Cat1: ItemCategory, Cat2: ItemCategory) => {
    if (Cat1.categoryOrder > Cat2.categoryOrder) {
      return 1;
    } else {
      return -1;
    }
  }

  const parentCategory = categorieStore.categories.find((category: ItemCategory) => {
    return category.id == categoryId;
  })

  console.log("sub categories", categoryId, getSubCategories(categoryId));


  return (
    <AdminLayout >
      <div className="page">
        {IsLoading && <Loading title="Loading data..." />}
        {!IsLoading && HasError && (
          <div>Error occured while loading categories</div>
        )}
        {!IsLoading && (
          <React.Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between " }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {CurrentCategories.length != 0 && <>
                  {(categoryId == 1) && <h1>Root Categories</h1>}
                  {categoryId !== 1 && <h1>{parentCategory?.name} Sub Categories</h1>}
                </>}
                {CurrentCategories.length == 0 && <>
                  <h1>Products in {parentCategory?.name} Category</h1>
                </>}
                <a
                  href="#"
                  title="Refresh"
                  style={{
                    background: "rgb(213 218 226)",
                    margin: "0px 8px",
                    padding: "4px",
                    lineHeight: "18px",
                    height: "26px",
                    borderRadius: "4px"
                  }}

                  onClick={(e) => {
                    e.preventDefault();
                    if (IsSaving) {
                      return;
                    }
                    setIsSaving(true);
                    categorieStore.isLoaded = false;
                    loadCategories(true);
                    setTimeout(() => {
                      setIsSaving(false);
                    }, 500);
                  }}
                >
                  {!IsSaving &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <RefreshIcon />
                    </i>}
                  {IsSaving &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <LoadingIconSimple />
                    </i>}
                </a>

                <Breadcrumb currentId={categoryId} rootId={1} items={categorieStore.categories}
                  onSelect={(category: any) => {
                    navigate('/categories/' + category);
                  }}
                />
              </div>
              <div>

                <button
                  className="primary"
                  onClick={() => {
                    navigate("/categories/new");
                  }}
                  style={{ marginLeft: "8px" }}
                >
                  <i className="svg-icon">
                    <AddIcon />
                  </i>
                  Create new category
                </button>
                {CurrentCategories.length == 0 &&
                  <button
                    className="primary"
                    onClick={() => {
                      navigate("/products/new");
                    }}
                    style={{ marginLeft: "8px" }}
                  >
                    <i className="svg-icon">
                      <AddIcon />
                    </i>
                    Create new product
                  </button>}

              </div>
            </div>

            <React.Fragment>
              {categorieStore.categories.length === 0 && (
                <NotFound
                  title="No categories found"
                  message="Product categories will appear here."
                />
              )}
              {CurrentCategories.length == 0 &&
                <>
                  <div className="grid-6-widgets">
                    {Products.map((product, index) => {
                      return <ProductCard product={product} key={index} confirmDeleteProduct={() => {

                      }} />
                    })}
                  </div>
                </>
              }

              <div className="grid-6-widgets">
                {CurrentCategories.map((category, index) => {
                  if (
                    index >= (CurrentPage - 1) * PageSize &&
                    index < CurrentPage * PageSize
                  ) {
                    return (
                      <div
                        key={index}
                        className="card shadow-well-sm"
                        style={{
                          borderRadius: "6px",
                          cursor: "pointer"
                        }}
                        onClick={(e) => {
                          navigate("/categories/" + category.id);
                        }}
                      >
                        <div
                          style={{
                            height: "200px",
                            overflow: "hidden"
                          }}
                          className=""
                        >
                          <img
                            src={category.imagePath}
                            className=""
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                              borderRadius: "6px 6px 0px 0px",
                            }}
                          />
                          <div
                            className="heading"
                            style={{
                              padding: "6px",
                              textAlign: "center",
                            }}
                          >
                            <h3 style={{ fontSize: "16px", margin: "4px", color: "#333" }}>{category.name}</h3>
                            {category.description && (
                              <p>{category.description}</p>
                            )}
                          </div>
                        </div>
                        <div className="actions" style={{ padding: "4px 0px" }}>
                          <a
                            href="#"
                            className="button bg-default"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate("/categories/" + category.id + "/settings");
                            }}
                          >
                            <i className="svg-icon">
                              <EditIcon />
                            </i>
                          </a>

                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              confirmDeleteCategory(category);
                            }}
                            className="button button-big bg-danger"
                          >
                            <i className="svg-icon">
                              <TrashIcon />
                            </i>
                          </a>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </AdminLayout>
  );
};

export default Categories;
