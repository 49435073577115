import React from 'react';
import Overview from '../component/Overview';

import { getProductCountEndPoint, getShopsEndPoint, getUserCountEndPoint } from "../api/api";
import { OrderIcon, ProductIcon, ShopIcon, SpaceIcon, UserIcon } from "../component/Icons";
import { useNavigate } from 'react-router-dom';
import { getOrdersEndPoint } from '../Order/orderApi';

const getCount = (data: any) => {
    return data.count;
};

const ShopHome = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="overview">
                <Overview
                    dataSource={getShopsEndPoint}
                    icon={ShopIcon}
                    title="Shops"
                    isAuthenticated={true}
                    getValueFromData={getCount}
                    onClick={() => {
                        navigate("shops");
                    }} />
                <Overview
                    dataSource={getProductCountEndPoint}
                    icon={ProductIcon}
                    title="Products"
                    isAuthenticated={true}
                    getValueFromData={getCount}
                    onClick={() => {
                        navigate("products");
                    }} />

                <Overview
                    dataSource={getOrdersEndPoint}
                    icon={OrderIcon}
                    title="Orders"
                    isAuthenticated={true}
                    getValueFromData={(data: any) => {
                        return data.number;
                    }}
                    onClick={() => {
                        navigate("orders");
                    }} />
            </div>
        </>
    );
    
};

export default ShopHome;