import React, { useContext } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { getSideBarMenu, getUserType } from "../helper";
import AppContext from "./AppContext";

const Sidebar = ({ }: {}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = location.pathname;
  const { appContext, setAppContext } = useContext(AppContext);

  const sideBarMenus = getSideBarMenu(appContext);
  return (
    <aside className="sidenav">
      {/* {JSON.stringify(appContext)} */}
      <div className="sidenav-header">
        <img
          src={
            !appContext.isSideBarOpen
              ? "/images/logo.png"
              : "/images/logo-medium.png"
          }
          style={{ height: "100%" }}
          alt="logo"
        />
      </div>
      <ul className="list">
        {sideBarMenus.map((menu, index) => {
          let activeClass = "";
          let Icon = menu.icon;
          if (menu.path == "/") {
            if (currentView == menu.path) {
              activeClass = "active";
            }
          } else {
            if (currentView.indexOf(menu.path) === 0) {
              activeClass = "active";
            }
          }
          return (
            <li
              className={"item " + activeClass}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(menu.path);
              }}
            >
              <Icon />
              <span>{menu.title}</span>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
