import React, { useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";


const Marker = (options: Partial<google.maps.MarkerOptions>) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};


export default Marker;