import React, { useState, useEffect, useContext } from "react";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./App.css";

import {
  userCredentials,
  fetchProfileData,
  logout,
} from "./api/api";
import { ItemCategory, IUser } from "./interface";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import { toast } from "react-hot-toast";
import {
  deleteDeviceId,
  getUserType,
  readFromCookie,
  removeFromCookie,
  saveToCookie,
} from "./helper";
import LoginPage from "./pages/Login";
import Categories from "./Category/Categories";
import Category from "./Category/Category";
import Loader from "./component/Loader/Loader";
import Products from "./Product/Products";
import Shops from "./Shop/Shops";
import Shop from "./Shop/Shop";
import Product from "./Product/Product";
import Users from "./User/Users";
import Orders from "./Order/Orders";
import Order from "./Order/Order";
import ShopView from "./Shop/ShopView";
import User from "./User/User";
import UserView from "./User/UserView";
import Carts from "./Cart/Carts";
import AppContext, {
  appParams,
  defaultAppContext,
} from "./component/AppContext";
import { getMyShop } from "./Shop/shopApi";
import SignupPage from "./pages/Signup";


function App() {
  const [IsLoading, setIsLoading] = useState(true);
  let isActive = true;
  const [appContext, setAppContext] = useState<appParams>(defaultAppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    verifyLogin();
    return () => {
      isActive = false;
    };
  }, []);

  const handleLogout = () => {
    removeFromCookie("istorecore");
    deleteDeviceId();

    setAppContext({ ...appContext, isLoggedIn: false });
    logout()
      .then((result) => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  };

  const showNotification = (type: string, message: string) => {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
    //https://react-hot-toast.com/
  };

  const verifyLogin = () => {
    //in login path, doesn't need to perform any action & render screen
    if (location.pathname == "/login" || location.pathname == "/signup") {
      setIsLoading(false);
      return;
    }
    let cookieToken = readFromCookie("istorecore");
    if (cookieToken == "") {
      setIsLoading(false);
      navigate("/login");
      return;
    }
    let cookieJSON;
    try {
      cookieJSON = JSON.parse(cookieToken);
    } catch (error: any) {
      saveToCookie("istorecore", "");
      setIsLoading(false);
      navigate("/login");
      return;
    }
    userCredentials.username = cookieJSON.username;
    userCredentials.token = cookieJSON.token;

    fetchProfileData()
      .then((result) => {
        const context = {
          ...appContext,
          isLoggedIn: true,
          profile: result.profileImageUrl,
          role: result.role,
          userID: result.userID ? result.userID : 0,
          name: result.name,
        };
        setAppContext(context);
        if (getUserType(result.role) == "Shop") {
          getMyShopData(context);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        saveToCookie("istorecore", "");
        setIsLoading(false);
        navigate("/login");
        return;
      });
  };

  const getMyShopData = (context: any) => {
    getMyShop().then((result) => {
      setAppContext({
        ...context,
        shopID: result.id,
      });
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  };




  // console.log("DeviceId", navigator.userAgent);

  return (
    <React.Fragment>
      {IsLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!IsLoading && (
        <AppContext.Provider value={{ appContext, setAppContext }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/new" element={<Category />} />
            <Route
              path="/categories/:categoryId/settings"
              element={<Category />}
            />
            <Route path="/categories/:id" element={<Categories />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:productId/settings" element={<Product />} />
            <Route path="/products/new" element={<Product />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:orderId/settings" element={<Order />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/carts" element={<Carts />} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/shops/:shopId" element={<ShopView />} />
            <Route path="/shops/:shopId/settings" element={<Shop />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:userId" element={<UserView />} />
            <Route path="/users/:userId/settings" element={<User />} />
            <Route path="/settings" element={<User />} />
          </Routes>
        </AppContext.Provider>
      )}
    </React.Fragment>
  );
}

export default App;
