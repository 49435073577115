import { ResultType } from "@remix-run/router/dist/utils";
import { apiEndPoint, authHeaders } from "../api/api";
import { IAddress } from "../interface";

export const getAddresses = (userId: number) => {
  let endPoint = apiEndPoint + "/DeliveryAddress/?EndUserID=" + userId;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmptyAddress = (userId: number) => {
  return {
    // id: 0,
    name: "",
    deliveryAddress: "",
    endUserID: userId,
    latitude: 0,
    longitude: 0,
    phoneNumber: "",
  };
};

export const getAddress = (id: number | string) => {
  let endPoint = apiEndPoint + "/v1/DeliveryAddress/" + id;

  return new Promise<IAddress>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveAddress = (address: IAddress) => {
  console.log(address);
  let endPoint = apiEndPoint;
  let method = "POST";
  endPoint = endPoint + "/DeliveryAddress";
  if (address.id) {
    method = "PUT";
    endPoint = endPoint + "/" + address.id;
  }

  console.log("Saving new address", address);

  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method,
      headers: authHeaders(),
      body: JSON.stringify(address),
    })
      .then((result) => {
        if (result.ok) return resolve(result);
        else throw new Error(result.statusText);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export const deleteAddress = (address: IAddress) => {
  let endPoint = apiEndPoint + "/DeliveryAddress/" + address.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (result.ok) {
          return resolve(result);
        }
        throw new Error(result.statusText);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};
