import React from "react";
import { GoBackIcon, SelectedIcon } from "./Icons";

//implement a pagination component that will display the current page and the total number of pages.
//The component should have a previous and next button that will change the current page.

const Pagination = ({
  pageSize,
  noOfItems,
  onPageChange,
  currentPage,
}: {
  noOfItems: number;
  currentPage: number;
  onPageChange: Function;
  pageSize: number;
}) => {
  const pageCount = Math.ceil(noOfItems / pageSize);
  let displayedPageCount = pageCount < 5 ? pageCount : 5;

  return (
    <div className="pagination">
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPageChange(currentPage - 1);
        }}
        disabled={currentPage == 1}
      >
        <GoBackIcon />
      </button>

      <ul>
        {Array.from(Array(displayedPageCount).keys()).map((index) => {
          let pageNumber = index + 1;
          if (currentPage > 2) {
            pageNumber = index + currentPage - 2;
          }
          return (
            <li key={index}>
              <a
                href="#"
                className={currentPage == pageNumber ? "active" : ""}
                onClick={() => {
                  onPageChange(pageNumber);
                }}
              >
                {pageNumber}
              </a>
            </li>
          );
        })}
      </ul>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPageChange(currentPage + 1);
        }}
        disabled={currentPage == pageCount}
      >
        <SelectedIcon />
      </button>
    </div>
  );
};

export default Pagination;
