import React, {
  useState,
  useEffect,
} from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";

import {
  LoadingIconSimple,
  MapIcon,
  PhoneIcon,
  EditIcon,
  OrderIcon,
  TrashIcon,
  DevicesIcon,
  EmailIcon,
} from "../component/Icons";
import { getImageUrl, getRelativeTime, getUniqueId, slugify } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import { IUser } from "../interface";
import Toggle from "../component/Toggle/Toggle";
import Loading from "../component/Loading";
import Overview from "../component/Overview";
import { getOrdersEndPoint } from "../Order/orderApi";
import GoBackLink from "../component/GoBackLink";
import { getUser } from "./userApi";

import OverviewContainer from "../component/OverviewContainer";
import ManageAddress from "./ManageAddress";

const UserView = () => {
  const { userId } = useParams();
  const UserId = userId ? parseInt(userId) : 0;


  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [User, setUser] = useState<IUser>();


  const loadUser = () => {
    if (IsLoading) {
      return;
    }
    if (UserId == undefined) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getUser(UserId)
      .then((result: any) => {
        setUser(result);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log("error");
      });
  };


  useEffect(() => {
    loadUser();
    return () => { };
  }, []);

  const notify = (type: string, message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="User">
      <div
        className="page"
        style={{
          alignItems: "start",
          maxWidth: "1600px",
          justifyContent: "start",
        }}
      >
        {IsLoading && <Loading title="Loading data" />}
        {!IsLoading && (
          <>
            <div className="flex" style={{ justifyContent: "end" }}>
              <GoBackLink />
            </div>

            <div className="flex" style={{ alignItems: "start" }}>
              <div>
                <div
                  className="card shadow-popover"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "8px",
                    marginRight: "8px",
                  }}
                >
                  <div className="flex">
                    <div
                      className="thumbnail-cover"
                      style={{ height: "100px", width: "100px" }}
                    >
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={getImageUrl("User", User?.profileImageUrl)}
                      />
                    </div>
                    <div style={{ margin: "8px" }}>
                      <h1 style={{ margin: "8px 0px" }}>
                        {User?.name + " "}
                        {/* <div className="tag">
                          {getUserRegistrationStatus(User.registrationStatus)}
                        </div> */}
                        <Link to={"settings"} className="button bg-default">
                          <i className="svg-icon">
                            <EditIcon />
                          </i>
                        </Link>
                      </h1>
                      {IsSaving && (
                        <i className="svg-icon" style={{ fontSize: "40px" }}>
                          <LoadingIconSimple />
                        </i>
                      )}
                      <p style={{ margin: "0px" }}>
                        {User?.timestampCreated != undefined && <><span className="muted">Joined:</span> {getRelativeTime(User?.timestampCreated)}</>}
                      </p>
                    </div>
                  </div>
                  <p style={{ margin: "8px 0px", padding: "16px", lineHeight: "24px" }} className="muted shadow-well-sm">
                    <i className="svg-icon"><EmailIcon /></i>{" "}
                    {User?.eMail != undefined && <>{User.eMail}</>}{User?.eMail == undefined && <>
                      Add Email</>}<br />
                    <i className="svg-icon"><PhoneIcon /></i>{" "}
                    {User?.phone != undefined && <>{User.phone}</>}{User?.phone == undefined && <>
                      Add Phone</>}<br />
                  </p>
                  <p style={{ margin: "8px 0px", padding: "16px", lineHeight: "24px" }} className="muted ">
                    {User?.about}
                  </p>


                  <div className="actions" style={{ padding: "4px 0px" }}>
                    <Link to={"settings"} className="button bg-default">
                      <i className="svg-icon">
                        <TrashIcon />
                      </i>
                    </Link>
                  </div>
                </div>

              </div>
              <div className="" style={{ display: "flex", flex: "1 1", alignItems: "start", gridGap: "16px", flexWrap: "wrap" }}>

                <Overview
                  title="Orders"
                  dataSource={getOrdersEndPoint}
                  getValueFromData={(data: any) => {
                    return data.totalElements;
                  }}
                  icon={OrderIcon}
                  isAuthenticated={true}
                  styleClass="danger"
                  onClick={() => { }}
                />

                <Overview
                  title="Devices"
                  dataSource={getOrdersEndPoint}
                  getValueFromData={(data: any) => {
                    return data.totalElements;
                  }}
                  icon={DevicesIcon}
                  isAuthenticated={true}
                  styleClass="success"
                  onClick={() => { }}
                />
                <OverviewContainer title="Delivery Addresses" icon={MapIcon} styleClass="primary">
                  <ManageAddress userId={UserId} />
                </OverviewContainer>


              </div>
            </div>
          </>
        )}
      </div>
    </AdminLayout >
  );
};

export default UserView;
