import React, { useEffect, useState } from "react";
import { fetchJSONData } from "../api/api";
import { LoadingIconSimple } from "./Icons";

const OverviewStatic = (props: {
  icon: any;
  title: string;
  value: string;
  onClick: Function;
  styleClass?: string;
}) => {
  const Icon = props.icon;
  const className = props.styleClass ? props.styleClass : "";

  return (
    <div
      className="overviewcard"
      onClick={() => {
        props.onClick();
      }}
    >
      <div className={"icon " + className}>
        <Icon />
      </div>
      <div className="info">
        <p className="svg-icon">
          <span>{props.value}</span>
        </p>
        <h4>{props.title}</h4>
      </div>
    </div>
  );
};

export default OverviewStatic;
