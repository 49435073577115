import { apiEndPoint, authHeaders } from "../api/api";
import { Item, ItemCategory } from "../interface";
import { categorieStore } from "./categoryStore";

export const getCategories = () => {
  let endPoint = apiEndPoint + "/v1/categories";
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSubCategories = (id: number) => {
  let categories = categorieStore.categories;
  let subCategories: Array<ItemCategory> = [];
  categories.forEach((category) => {
    if (category.parentCategoryId === id) {
      subCategories.push(category);
      if (category.id) subCategories.push(...getSubCategories(category.id));
    }
  });
  return subCategories;
};

export const getCategory = (id: number) => {
  let endPoint = apiEndPoint + "/v1/categories/" + id;
  return new Promise<ItemCategory>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveCategory = (category: ItemCategory) => {
  let endPoint = apiEndPoint + "/v1/categories/";
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify(category),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCategory = (category: ItemCategory) => {
  let endPoint = apiEndPoint + "/v1/categories/" + category.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          isSuccess = false;
        }
        return result.json();
      })
      .then((result: any) => {
        if (isSuccess) {
          resolve(result);
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
