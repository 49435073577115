import React, { useEffect, useState } from 'react';
import { IAddress } from '../interface';
import { deleteAddress, getAddresses, getEmptyAddress, saveAddress } from './addressAPI';
import { toast } from 'react-hot-toast';
import Modal from '../component/Modal';
import AddressEdit from './AddressEdit';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingIconSimple } from '../component/Icons';
import Address from './Address';
import NotFound from '../component/NotFound';

const ManageAddress = ({ userId }: { userId: number }) => {
    const [Addresses, setAddresses] = useState<Array<IAddress>>([]);
    const [SelectedAddress, setSelectedAddress] = useState(getEmptyAddress(userId));
    const [IsAddressLoaded, setIsAddressLoaded] = useState(false);
    const [IsModalVisible, setIsModalVisible] = useState(false);
    const [IsSaving, setIsSaving] = useState(false);


    const loadAddress = () => {
        setIsAddressLoaded(false);
        getAddresses(userId).then((result: any) => {
            setIsAddressLoaded(true);
            setAddresses(result);
        }).catch((error) => {
            toast.error("Error loading address");
            console.log(error);
            setIsAddressLoaded(true);
        });
    }

    const editAddress = (address: IAddress) => {
        setSelectedAddress(address);
        setIsModalVisible(true);
        setIsModalVisible(true);
    }

    const addAddress = () => {
        setSelectedAddress(getEmptyAddress(userId));
        setIsModalVisible(true);
        setIsModalVisible(true);
    }

    const saveAdd = () => {
        if (!SelectedAddress) {
            return;
        }
        setIsSaving(true);
        saveAddress(SelectedAddress).then((result) => {
            setIsSaving(false);
            setIsModalVisible(false);
            toast.success("Saved successfully");
            loadAddress();
        }).catch((error) => {
            setIsSaving(false);
            console.log(error);
            toast.error("Error saving address");
        })
        console.log(SelectedAddress);
    }

    const handleDelete = (address: IAddress) => {
        deleteAddress(address).then((result) => {
            toast.success("Address has been deleted succesfully");
            loadAddress();
        }).catch((error) => {
            console.log(error);
            toast.error("Error de;eting address");
        })
    }


    const confirmDeleteAddress = (address: IAddress) => {
        confirmAlert({
            title: "Confirm delete",
            message: "Are you sure to DELETE the address?",
            buttons: [
                {
                    label: "Delete",
                    onClick: () => handleDelete(address),
                },
                {
                    label: "No",
                    onClick: () => console.log("Cancelled"),
                },
            ],
        });
    };

    useEffect(() => {
        loadAddress();
    }, []);


    return (
        <>

            <div style={{ flex: "1 1" }}>
                {!IsAddressLoaded && <LoadingIconSimple />}
                {IsAddressLoaded && <>
                    {Addresses.map((address, index) => {
                        return <Address key={index} address={address} deleteFn={confirmDeleteAddress} edit={() => { editAddress(address) }} />
                    })}

                    {Addresses.length == 0 && <NotFound title='Not found' message='Addresses not available' />}

                    <a href="#" className='mt-2' style={{ display: "block" }} onClick={(e) => {
                        e.preventDefault();
                        addAddress();
                    }}>Add Address</a>
                </>}
            </div>
            {IsModalVisible && (
                <Modal
                    title="Change Detail"
                    onClose={() => {
                        setIsModalVisible(!IsModalVisible);
                    }}
                    isVisible={IsModalVisible}
                    content={
                        <AddressEdit address={SelectedAddress} update={editAddress} save={saveAdd} IsBeingSaved={IsSaving} />
                    }
                />
            )}
        </>
    );
};

export default ManageAddress;