import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import "./PictureWall.css";
import { getUniqueId } from "../../helper";
import { IFile } from "../../interface/index";
import { authHeaders, authHeadersWithoutContentType } from "../../api/api";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const PictureWall = ({ onChange, maxCount, fileList, notify, handleUpload, handleDelete }: {
  onChange: Function;
  maxCount: number;
  fileList: IFile[];
  notify: Function;
  handleUpload: Function,
  handleDelete: Function
}) => {

  const [FileList, setfileList] = useState<Array<IFile>>([]);
  const uploadInput = useRef<HTMLInputElement>(null);

  const handleChangeUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files;
      let fileList: IFile[] = [];
      for (let i = 0; i < files.length; i++) {
        const file: IFile = files[i];
        fileList.push(file);
      }
      handleFileUpload(fileList);
    }
  };

  const handleDropUpload = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.files) {
      const files = e.dataTransfer.files;
      let fileList: IFile[] = [];
      for (let i = 0; i < files.length; i++) {
        const file: IFile = files[i];
        fileList.push(file);
      }
      handleFileUpload(fileList);
    }
  }

  useEffect(() => {
    setfileList(fileList);
    return () => {
    }
  }, [fileList]);

  const handleFileUpload = (files: IFile[]) => {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!file.type.match("image.*")) {
        notify("Unsupported file type : " + file.type);
        return false;
      } else {
        if (FileList.length >= maxCount) {
          notify(

            "Only " + maxCount + " files can be uploaded"
          );
          return false;
        }
        const fileIndex = FileList.length;
        setfileList([...FileList, file]);

        handleUpload(file)
          .then((response: any) => {
            onChange([...FileList.slice(0, fileIndex), response, ...FileList.slice(fileIndex + 1)]);
            setfileList([...FileList.slice(0, fileIndex), response, ...FileList.slice(fileIndex + 1)]);
          })
          .catch((error: any) => {
            notify(
              error.message ? error.message : "Error occurred : " + error
            );
            setfileList([...FileList.slice(0, fileIndex), ...FileList.slice(fileIndex + 1)]);
            return false;
          });
      }
    }
    return false;
  };

  const handleFileDelete = (uuid?: string) => {
    if (uuid) {
      handleDelete(uuid).then((result: any) => {
        let fileList = FileList.filter(function (file) {
          return file.uuid != uuid;
        });
        setfileList(fileList);
      }).catch((error: any) => {
        notify("Unable to delete the image: " + error)
      });
      //set some alert

    }
  }

  return (
    <div style={{ zoom: 1, display: "inline-block", width: "100%" }}>
      <div
        style={{
          boxSizing: "border-box",
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          marginBottom: 0,
          padding: 0,
          color: "rgba(0, 0, 0, 0.65)",
          fontSize: "14px",
          lineHeight: "1.5",
          listStyle: "none",
          zoom: 1,
        }}
      >
        {FileList.map((file, index) => {
          return (
            <div
              style={{
                float: "left",
                width: "104px",
                height: "104px",
                marginTop: 0,
                marginRight: "8px",
                marginBottom: "8px",
                marginLeft: "0",
                position: "relative",
                padding: "8px",
                border: "1px solid #e2e0e0",
                borderRadius: "4px",
              }}
              key={index}
              className="PictureWallImage"
            >
              {file.url && (
                <React.Fragment>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      overflow: "hidden",
                    }}
                    className="PW_Overlay"
                  >
                    <img
                      src={file.url}
                      alt={file.name}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      zIndex: 10,
                      transform: "translate(-50%, -50%)",
                      transition: "all 0.3s",
                    }}
                    className="PW_Buttons"
                  >
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Preview file"
                      data-toggle="lightbox"
                    >
                      <i aria-label="icon: eye-o">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="eye"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z" />
                        </svg>
                      </i>
                    </a>
                    <i
                      aria-label="icon: delete"
                      title="Remove file"
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleFileDelete(file.uuid);
                      }}
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="delete"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                      </svg>
                    </i>
                  </span>
                </React.Fragment>
              )}
              {!file.url && (
                <React.Fragment>
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      zIndex: 10,
                      transform: "translate(-50%, -50%)",
                      transition: "all 0.3s",
                    }}
                  >
                    Uploading...
                  </span>
                </React.Fragment>
              )}
            </div>
          );
        })}
      </div>
      {FileList.length < maxCount && <div
        onDrop={handleDropUpload}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        className="PictureWallUploadContainer"
        style={{
          display: "table",
          float: "left",
          width: "104px",
          height: "104px",
          textAlign: "center",
          verticalAlign: "top",
          backgroundColor: "#fafafa",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <span
          tabIndex={0}
          role="button"
          style={{
            display: "table-cell",
            width: "100%",
            height: "100%",
            padding: "8px",
            textAlign: "center",
            verticalAlign: "middle",
          }}
          onClick={(e) => {
            uploadInput.current?.click();
          }}
        >
          <input
            type="file"
            className="fileUploaderinput"
            accept=""
            style={{ display: "none" }}
            onChange={handleChangeUpload}
            ref={uploadInput}
          />
          <div>
            <i aria-label="icon: plus">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="plus"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
                <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
              </svg>
            </i>
            <div>Upload</div>
          </div>
        </span>
      </div>}
    </div>
  );


}

export default PictureWall;
