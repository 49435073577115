import React from "react";
import { IOrder, Item } from "../interface";
import {
    SettingsIcon,
    TopicsIcon,
    TrashIcon,
    ViewIcon,
} from "../component/Icons";
import { Link } from "react-router-dom";
import { getImageUrl } from "../helper";
import { userCredentials } from "../api/api";

const OrderCard = ({
    order,
    confirmDeleteOrder,
}: {
    order: IOrder;
    confirmDeleteOrder: Function;
}) => {
    return (
        <div className="item cursor">
            <div className="heading" style={{ display: "flex" }}>
                <div
                    className="thumbnail-cover"
                    style={{
                        height: "50px",
                        width: "50px",
                        overflow: "hidden",
                        borderRadius: "4px",
                        position: "relative"
                    }}
                >
                    {/* <img
                        src={getImageUrl("Order", order.profileImageUrl)}
                        style={{

                            width: "50px",
                            height: "50px",
                            objectFit: "cover",

                        }}
                    /> */}
                </div>
                <div>
                    <h3>
                        {order.id}
                        {" (#" + order.id + ")"}
                    </h3>
                    {/* {order.eMail && <p>{order.}</p>}
                    {order.phone && <p>{order.phone}</p>} */}
                </div>
            </div>
            <div>
                {/* {order.role} */}
            </div>

            <div className="actions">
                <a href={"/orders/" + order.id} className="button button-big bg-default">
                    <ViewIcon />
                </a>
                <Link
                    to={"/orders/" + order.id + "/settings"}
                    className="button button-big bg-default"
                >
                    <i className="svg-icon">
                        <SettingsIcon />
                    </i>
                </Link>

                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        confirmDeleteOrder(order);
                    }}
                    className="button button-big bg-danger"
                >
                    <i className="svg-icon">
                        <TrashIcon />
                    </i>
                </a>
            </div>
        </div>
    );
};

export default OrderCard;
