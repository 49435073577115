import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__copyright">&copy; 2022&nbsp;
        <a href="/" target="_blank" rel="noreferrer">IndianStores.co.uk</a></div>
      <div className="footer__signature">
        {"Powered by "}
        <a href="https://adhithi.co.uk" target="_blank" rel="noreferrer">
          Adhithi Ltd
        </a>


      </div>
    </footer>
  );
}

export default Footer;
