import { IOrder, Item } from "../interface";
import { apiEndPoint, authHeaders, userCredentials } from "../api/api";

export const getOrdersEndPoint = apiEndPoint + "/v1/orders";

export const getOrders = (
  pageNumber: number,
  pageSize: number,
  query: string | null
) => {
  if (query == null) {
    query = "";
  }
  let endPoint =
    getOrdersEndPoint +
    "?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    pageSize +
    "&sort_by=id" +
    "&sort_direction=desc" +
    "&query=" +
    query;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          throw new Error(result.statusText);
        }
        return result.json();
      })
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getEmptyOrder = (): IOrder => {
  return {
    id: 0,
    endUserId: 0,
    shopId: 0,
    deliveryCharges: 0,
    itemCount: 0,
    itemtotal: 0,
    netPayable: 0,
    statusCurrent: 0,
    deliveryMode: 0,
    paymentMode: 0,
    paymentStatus: 0,
    dateTimePlaced: Date(),
    items: [],
  };
};

export const getOrder = (id: number) => {
  let endPoint = apiEndPoint + "/v1/orders/" + id;

  return new Promise<Item>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveOrder = (order: IOrder) => {
  let endPoint = apiEndPoint;
  let method = "POST";
  if (order.id) {
    method = "PUT";
  }
  endPoint = endPoint + "/v1/orders";
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify(order),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteOrder = (order: IOrder) => {
  let endPoint = apiEndPoint + "/v1/orders/" + order.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          isSuccess = false;
        }
        return result.json();
      })
      .then((result: any) => {
        if (isSuccess) {
          resolve(result);
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// export const getOrdersByRoles = (
//   pageNumber: number,
//   sort_by: string,
//   sort_direction: string,
//   orderId: number
// ) => {
//   let endPoint =
//     apiEndPoint +
//     "/v1/orders?offset=" +
//     (pageNumber - 1) +
//     "&limit=" +
//     12 +
//     "&sort_by=" +
//     sort_by +
//     "&sort_direction=" +
//     sort_direction +
//     "&query=" +
//     orderId;
//   return new Promise((resolve: Function, reject: Function) => {
//     fetch(endPoint, {
//       method: "GET",
//       headers: authHeaders(),
//     })
//       .then((result) => result.json())
//       .then((json) => resolve(json))
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
