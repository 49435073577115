import React, { ChangeEvent, useState } from "react";
import { IAddress } from "../interface";
import { EditIcon, PhoneIcon, TrashIcon } from "../component/Icons";
import MapPicker from "../component/MapPicker/MapPicker";
import { getLocation } from "../helper";
import { LoaderIcon } from "react-hot-toast";

const AddressEdit = ({
  address,
  IsBeingSaved,
  update,
  save,
}: {
  address: IAddress;
  IsBeingSaved: boolean;
  update: Function;
  save: Function;
}) => {
  const [zoomLevel, setzoomLevel] = useState(10);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    update({ ...address, [name]: value });
  };

  const getCurrentLocation = () => {
    getLocation().then((location: any) => {
      // setCoordinates({ latCenter: location.lat, lonCenter: location.lng })
      console.log(location.coords);
      update({
        ...address,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
      setzoomLevel(20);
    });
  };

  return (
    <address style={{ minWidth: "550px", flexDirection: "column", alignItems: "start", display: "flex" }} >
      <div style={{ display: "flex" }} className="mt-1 flex-fill">
        <div>
          <label htmlFor="add-name">Name</label> <br />
          <input
            type={"text"}
            id="add-name"
            name="name"
            value={address.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="add-phone">Contact Number</label> <br />
          <input
            type={"text"}
            id="add-phone"
            name="phoneNumber"
            value={address.phoneNumber}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mt-1">
        <label htmlFor="address-text">Address</label>
        <br />
        <input
          type={"text"}
          id="address-text"
          name="deliveryAddress"
          value={address.deliveryAddress}
          onChange={handleChange}
          style={{ width: "500px" }}
        />
      </div>

      <div style={{ flexGrow: 1, padding: "0px" }}>
        <div
          className="flex card"
          style={{ padding: "8px", margin: "8px 0px", minHeight: "40px" }}
        >
          <div style={{ margin: "0px 8px 0px 0px", display: "block" }}>
            <label>Latitude</label><br />
            <input
              type="text"
              value={address.latitude}
              name="latCenter"
              onChange={handleChange}
            />
          </div>
          <div style={{ margin: "0px 8px 0px 0px", display: "block" }}>
            <label>Longitude</label><br />
            <input
              type="text"
              value={address.longitude}
              name="lonCenter"
              onChange={handleChange}
            />
          </div>
          <div style={{ margin: "0px 8px 0px 0px", fontSize: "16px" }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                getCurrentLocation();
              }}
            >
              {" "}
              Use Current
            </a>
          </div>
        </div>
        <MapPicker
          counter={1}
          lat={address.latitude ? address.latitude : 0}
          lng={address.longitude ? address.longitude : 0}
          zoomNum={zoomLevel}
          setCoordinates={(cord: any) => {
            if (
              cord.lat != address.latitude ||
              cord.lng != address.longitude
            ) {
              update({ ...address, latitude: cord.lat, longitude: cord.lng });
            }
          }}
        />
      </div>
      <div style={{ alignSelf: "end" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            save();
          }}
          style={{ marginTop: "8px" }}
          disabled={IsBeingSaved}
        >
          {IsBeingSaved && <LoaderIcon />}
          {!IsBeingSaved && <i className="svg-icon">
            <EditIcon />
          </i>}
          {address.id ? "Update" : "Save"}
        </button>
      </div>
    </address >
  );
};

export default AddressEdit;
