import React, { Children, ComponentType, ReactNode, useEffect, useState } from "react";
import { fetchJSONData } from "../api/api";
import { LoadingIconSimple } from "./Icons";


const OverviewContainer = ({ icon, title, styleClass, children }: {
  icon: any;
  title: string;
  styleClass?: string;
  children: ReactNode
}) => {
  const Icon = icon;
  const className = styleClass ? styleClass : "";

  //style the container


  return (
    <div
      className="overview-data"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div className={"icon " + className}>
          <Icon />
        </div>
        <h3>
          {title}
        </h3>
      </div>

      <div className="info">
        {children}
      </div>
    </div>
  );
};

export default OverviewContainer;
