import React, { useEffect } from "react";

const Modal = ({
  title,
  content,
  onClose,
  isVisible
}: {
  title: string;
  content: React.ReactNode;
  onClose?: Function;
  isVisible?: boolean
}) => {

  useEffect(() => {
    return () => {
    };
  }, []);

  return (
    <div className={"modal" + (isVisible ? " show-modal" : "")}>
      <div className="modal-content">
        {onClose && <span
          className="close-button"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          &times;
        </span>}
        <h1>{title}</h1>
        {content}
      </div>
    </div>
  );
};

export default Modal;
