import { IShop } from "../interface";
import { apiEndPoint, authHeaders, userCredentials } from "../api/api";

export const getShops = (
  pageNumber: number,
  pageSize: number,
  query: string | null
) => {
  if (query == null) {
    query = "";
  }
  let endPoint =
    apiEndPoint +
    "/v1/shops?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    pageSize +
    "&sort_by=id" +
    "&sort_direction=desc" +
    "&query=" +
    query;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmptyShop = () => {
  return {
    adminid: 0,
    name: "",
    shopName: "",
    shopAddress: "",
    city: "",
    postCode: "",
    logoImagePath: "",
    deliveryRange: 5,
    latCenter: 0,
    lonCenter: 0,
    pickFromShopAvailable: true,
    homeDeliveryAvailable: true,
    isOpen: true,
    itemUpdatePermitted: false,
  };
};

export const getShop = (id: number, view: string) => {
  let endPoint = apiEndPoint + "/v1/ShopDetails/ForDetailScreen?ShopID=" + id;
  if (view == "Edit") {
    endPoint = apiEndPoint + "/v1/ShopDetails/ForEditScreen?ShopID=" + id;
  } else if (view == "dashboard-shop") {
    endPoint = apiEndPoint + "/v1/ShopDetails/ForShopDashboard?ShopID=" + id;
  }
  return new Promise<IShop>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMyShop = () => {
  let endPoint = apiEndPoint + "/v1/shops/my";

  return new Promise<IShop>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveShop = (shop: IShop) => {
  let endPoint = apiEndPoint;
  console.log(userCredentials);
  if (userCredentials.role == 1) {
    endPoint = endPoint + "/v1/shop/UpdateByAdmin/" + shop.shopID;
  } else {
    endPoint = endPoint + "/v1/shop/UpdateBySelf/";
  }
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "PUT",
      headers: authHeaders(),
      body: JSON.stringify(shop),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteShop = (shop: IShop) => {
  let endPoint = apiEndPoint + "/v1/shops/" + shop.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          isSuccess = false;
        }
        return result.json();
      })
      .then((result: any) => {
        if (isSuccess) {
          resolve(result);
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductsByShop = (
  pageNumber: number,
  sort_by: string,
  sort_direction: string,
  shopId: number
) => {
  let endPoint =
    apiEndPoint +
    "/v1/products?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    12 +
    "&sort_by=" +
    sort_by +
    "&sort_direction=" +
    sort_direction +
    "&query=" +
    shopId;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};
