import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import "./style.css";

interface Props {
  label: ReactNode;
  options: Array<ReactNode>;
}

const Menu = ({ label, options }: Props) => {
  return (
    <div className="dropdown">
      <div className="dropbtn"> {label}</div>
      <div className="dropdown-content">
        {options.map((option, index) => {
          return <React.Fragment key={index}>{option}</React.Fragment>;
        })}
      </div>
    </div>
  );
};

export default Menu;
