import React, { Children, ReactNode, useState, useContext } from "react";
import { Toaster } from "react-hot-toast";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import AppContext from "../component/AppContext";

const AdminLayout = ({
    children,
    searchableType,
}: {
    children: ReactNode;
    searchableType?: string;
}) => {
    const [SearchableType, setSearchableType] = useState(
        searchableType ? searchableType : ""
    );
    const { appContext, setAppContext } = useContext(AppContext);

    return (
        <div
            className={
                "app" +
                (appContext.isSideBarOpen ? " expanded" : "") +
                (appContext.isInDarkMode ? " theme-dark" : "")
            }
        >
            <Header searchableType={searchableType} />
            <Sidebar />
            <main className={"main"}>{children}</main>
            <Footer />
            <Toaster />
        </div>
    );
};

export default AdminLayout;
