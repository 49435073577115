import React, { useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./Map";
import Marker from "./Marker";
// import { latLng } from "./dataStore";
import { getLocation } from "../../helper";

const MapPicker = ({
    lat,
    lng,
    zoomNum,
    setCoordinates,
    counter,
}: {
    lat: number;
    lng: number;
    zoomNum: number;
    counter: number;
    setCoordinates: Function;
}) => {
    const render = (status: Status) => {
        return <h1>{status}</h1>;
    };
    const [Counter2, setCounter2] = useState(1);
    const [latLngCenter, setLatLngCenter] = useState<any>({});

    const onClick = (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
            setCoordinates({
                lat: e.latLng?.lat(),
                lng: e.latLng?.lng(),
            });
            setLatLngCenter({
                lat: e.latLng?.lat(),
                lng: e.latLng?.lng(),
            });
        }
    };



    useEffect(() => {
        setLatLngCenter({
            lat: lat,
            lng: lng,
        });

    }, [lat, lng, counter, zoomNum]);

    const onIdle = (m: google.maps.Map) => {
        // latLng.mapCenter = m.getCenter()!.toJSON();
        setLatLngCenter(m.getCenter()!.toJSON());
    };

    return (
        <Wrapper apiKey={"AIzaSyAbMuXITsNJqqe3E686wzYH9bbFUlaF0uo"} render={render}>
            <Map
                center={latLngCenter}
                onClick={onClick}
                onIdle={onIdle}
                zoom={zoomNum}
                style={{ flexGrow: "1", height: "100%" }}
                zoomControl={true}
                scaleControl={true}
                mapTypeControl={false}
                streetViewControl={false}
                fullscreenControl={false}
                refreshCounter2={Counter2}
            >
                <Marker position={latLngCenter} />
            </Map>
        </Wrapper>
    );
};

export default MapPicker;
