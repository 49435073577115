import { IUser, Item } from "../interface";
import { apiEndPoint, authHeaders, userCredentials } from "../api/api";

export const getUsers = (
  pageNumber: number,
  pageSize: number,
  query: string | null
) => {
  if (query == null) {
    query = "";
  }
  let endPoint =
    apiEndPoint +
    "/v1/users?offset=" +
    (pageNumber - 1) +
    "&limit=" +
    pageSize +
    "&sort_by=id" +
    "&sort_direction=desc" +
    "&query=" +
    query;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          throw new Error(result.statusText);
        }
        return result.json();
      })
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmptyUser = () => {
  return {
    name: "",
    itemDesc: "",
    itemDescriptionLong: "",
    itemCategoryId: 0,
    itemImageUrl: "",
    quantityUnit: "",
    listPrice: 1,
    isEnabled: true,
  };
};

export const getUser = (id: number | string) => {
  let endPoint = apiEndPoint + "/v1/users/" + id;

  return new Promise<Item>((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveUser = (user: IUser) => {
  let endPoint = apiEndPoint;
  let method = "POST";
  if (user.id) {
    method = "PUT";
  }
  endPoint = endPoint + "/v1/users";
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify(user),
    })
      .then((result) => result.json())
      .then((json) => resolve(json))
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteUser = (user: Item) => {
  let endPoint = apiEndPoint + "/v1/users/" + user.id;
  return new Promise((resolve: Function, reject: Function) => {
    let isSuccess = true;
    fetch(endPoint, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((result) => {
        if (!result.ok) {
          isSuccess = false;
        }
        return result.json();
      })
      .then((result: any) => {
        if (isSuccess) {
          resolve(result);
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const SendVerificationCode = (
  type: "email" | "phone" | "address",
  value: string
) => {
  return new Promise((resolve: Function, reject: Function) => {
    return fetch(
      apiEndPoint +
        "/v1/users/SendVerificationCode/?type=" +
        type +
        "&value=" +
        encodeURIComponent(value),
      {
        method: "POST",
        headers: authHeaders(),
      }
    )
      .then((result) => {
        if (result.ok) return resolve(result);
        else return reject(result.statusText);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const UpdateContact = (
  userId: number,
  type: "email" | "phone" | "address",
  value: string,
  verificationCode: string
) => {
  return fetch(
    apiEndPoint +
      "/v1/users/" +
      userId +
      "/" +
      type +
      "?value=" +
      value +
      "&code=" +
      verificationCode,
    {
      method: "PUT",
      headers: authHeaders(),
    }
  );
};

// export const getUsersByRoles = (
//   pageNumber: number,
//   sort_by: string,
//   sort_direction: string,
//   userId: number
// ) => {
//   let endPoint =
//     apiEndPoint +
//     "/v1/users?offset=" +
//     (pageNumber - 1) +
//     "&limit=" +
//     12 +
//     "&sort_by=" +
//     sort_by +
//     "&sort_direction=" +
//     sort_direction +
//     "&query=" +
//     userId;
//   return new Promise((resolve: Function, reject: Function) => {
//     fetch(endPoint, {
//       method: "GET",
//       headers: authHeaders(),
//     })
//       .then((result) => result.json())
//       .then((json) => resolve(json))
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
