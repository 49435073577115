import React from "react";
import { IUser, Item } from "../interface";
import {
    SettingsIcon,
    TopicsIcon,
    TrashIcon,
    ViewIcon,
} from "../component/Icons";
import { Link } from "react-router-dom";
import { getImageUrl, getUserRoleName } from "../helper";
import { userCredentials } from "../api/api";

const UserCard = ({
    user,
    confirmDeleteUser,
}: {
    user: IUser;
    confirmDeleteUser: Function;
}) => {
    return (
        <div className="item cursor">
            <div className="heading" style={{ display: "flex" }}>
                <div
                    className="thumbnail-cover"
                    style={{
                        height: "50px",
                        width: "50px",
                        overflow: "hidden",
                        borderRadius: "4px",
                        position: "relative"
                    }}
                >
                    <img
                        src={getImageUrl("User", user.profileImageUrl)}
                        style={{

                            width: "50px",
                            height: "50px",
                            objectFit: "cover",

                        }}
                    />
                </div>
                <div>
                    <h3>
                        {user.name}
                        {" (#" + user.id + ")"}
                    </h3>
                    {user.eMail && <p>{user.eMail}</p>}
                    {user.phone && <p>{user.phone}</p>}
                </div> <span className="tag" style={{ fontSize: "12px", alignSelf: "center" }}>
                    {getUserRoleName(user.role)}
                </span>

            </div>


            <div className="actions">
                <Link
                    to={"/users/" + user.id}
                    className="button button-big bg-default"
                >
                    <i className="svg-icon">
                        <ViewIcon />
                    </i>
                </Link>

                <Link
                    to={"/users/" + user.id + "/settings"}
                    className="button button-big bg-default"
                >
                    <i className="svg-icon">
                        <SettingsIcon />
                    </i>
                </Link>

                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        confirmDeleteUser(user);
                    }}
                    className="button button-big bg-danger"
                >
                    <i className="svg-icon">
                        <TrashIcon />
                    </i>
                </a>
            </div>
        </div>
    );
};

export default UserCard;
