import { getDeviceId, getDeviceName, getImageUrl } from "../helper";
import { IOrderFilter, IShop, Item, ItemCategory, IUser } from "../interface";

export const apiEndPoint = "/api";

export const getShopsEndPoint = apiEndPoint + "/v1/shop/count";
export const getProductCountEndPoint = apiEndPoint + "/v1/products/count";
export const getUserCountEndPoint = apiEndPoint + "/v1/User/Count";

export const getCategoriesEndPoint = apiEndPoint + "";

export const userCredentials = {
  name: "",
  // userID: 0,
  username: "",
  token: "",
  profilePath: "",
  isProfileLoaded: false,
  profile: "",
  role: 10,
  roleName: "enduser",
};

//is logged in
// export const isLoggedIn = () => {
//   return userCredentials.userID > 0;
// };

export const authHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization:
      "Basic " + btoa(userCredentials.username + ":" + userCredentials.token),
  };
};

export const authHeadersWithoutContentType = () => {
  return {
    Authorization:
      "Basic " + btoa(userCredentials.username + ":" + userCredentials.token),
  };
};

// export const CheckUserExists = (
//   phoneOrEmail: string,
//   registrationMode: number
// ) => {
//   return fetch(
//     apiEndPoint +
//       "/v1/User/SignUp/CheckUserExists/" +
//       phoneOrEmail +
//       "?RegistrationMode=" +
//       registrationMode,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
// };

export const signup = (
  regMod: number,
  name: string,
  phone: string,
  email: string,
  userType: string,
  shopName: string,
  verificationCode: string
) => {
  let deviceId = getDeviceId();
  let deviceName = getDeviceName();
  return new Promise((resolve, reject) => {
    fetch(
      apiEndPoint +
        "/v1/users/signup/?regMode=" +
        regMod +
        "&phone=" +
        encodeURIComponent(phone) +
        "&email=" +
        encodeURIComponent(email) +
        "&name=" +
        name +
        "&userType=" +
        userType +
        "&verificationCode=" +
        verificationCode +
        "&deviceId=" +
        deviceId +
        "&shopName=" +
        shopName +
        "&deviceName=" +
        deviceName,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      response
        .json()
        .then((data) => {
          if (response.status == 200) {
            resolve(data);
          } else {
            reject(
              data.message
                ? data.message
                : data.error
                ? data.error
                : response.statusText
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const fetchJSONData = (EndPoint: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(EndPoint, {
      method: "GET",
      headers: authHeaders(),
    })
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchProfileData = (): Promise<IUser> => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/User/GetProfile", {
      method: "GET",
      headers: authHeaders(),
    })
      .then((response) => response.json())
      .then((result) => {
        result.profileImageUrl = getImageUrl("User", result.profileImageUrl);
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/users/logout", {
      method: "GET",
      headers: authHeaders(),
    })
      .then((response) => {
        if (response.status == 200) {
          return resolve(response);
        }
        return reject(response);
      })
      .catch((error) => {
        reject("Error while logging out");
      });
  });
};

//read token from the response data
export const verifyLoginUsingOtp = (
  registrationMode: number,
  email: string,
  phone: string,
  otp: string
) => {
  let endPoint =
    apiEndPoint +
    "/v1/users/login?regMode=" +
    registrationMode +
    "&deviceId=" +
    getDeviceId() +
    "&deviceName=" +
    getDeviceName() +
    "&email=" +
    encodeURIComponent(email) +
    "&phone=" +
    encodeURIComponent(phone) +
    "&otp=" +
    otp;
  userCredentials.username = getDeviceId();
  userCredentials.token = otp;
  return new Promise((resolve: Function, reject: Function) => {
    fetch(endPoint, {
      method: "POST",
      headers: authHeaders(),
    })
      .then((result) => {
        result.json().then((data) => {
          if (result.status == 200) {
            resolve(data);
          } else {
            reject(
              data.message
                ? data.message
                : data.error
                ? data.error
                : result.statusText
            );
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProfile = () => {
  return fetch(apiEndPoint + "/v1/User/GetProfile", {
    method: "GET",
    headers: authHeaders(),
  }).then((result) => result.json());
};
