import React, { useEffect, useState, useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";
import Confirm from "../component/Confirm";
import { CloseIcon, CopyIcon, FilterIcon, LoadingIconSimple, RefreshIcon, SearchIcon } from "../component/Icons";
import Loading from "../component/Loading";
import NotFound from "../component/NotFound";
import Pagination from "../component/Pagination";
import { Item } from "../interface";

import AdminLayout from "../layouts/AdminLayout";
import ProductCard from "./ProductCard";
import { deleteProduct, getProducts } from "./productApi";
import { toast } from "react-hot-toast";
import { getUserRoleName } from "../helper";
import { userCredentials } from "../api/api";
import CategoryFilter from "../Category/CategoryFilter";
import SearchContext from "../component/AppContext";
import AppContext from "../component/AppContext";

const Products = () => {
  const navigate = useNavigate();
  const [IsInitialLoading, setIsInitialLoading] = useState(true);
  const [IsSaving, setIsSaving] = useState(false);
  const [IsReloading, setIsReloading] = useState(false);
  const [products, setProducts] = useState<Array<Item>>([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [HasError, setHasError] = useState(false);
  const PageSize = 12;
  const [NoOfPages, setNoOfPages] = useState(0);
  const { appContext, setAppContext } = useContext(AppContext);

  const loadProducts = (pageNumber: number) => {
    console.log("appContext.search", appContext);
    setIsReloading(true);
    getProducts(pageNumber, "id", "desc", appContext.search, appContext.categoryId)
      .then((result: any) => {
        setNoOfPages(result.totalElements);
        setProducts(result.content);
        setIsInitialLoading(false);
        setIsReloading(false);
      })
      .catch((error: any) => {
        setIsInitialLoading(false);
        setIsReloading(false);
      });
  };

  //delete Product
  const deleteProductAct = (product: Item) => {
    setIsSaving(true);
    deleteProduct(product)
      .then((result) => {
        setIsSaving(false);
        toast.success("Product has been deleted successfully");
        loadProducts(CurrentPage);
      })
      .catch((error) => {
        setIsSaving(false);
        toast.error("Error: " + error);
      });
  };

  const confirmDeleteProduct = (product: Item) => {
    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure to DELETE the product?",
      buttons: [
        {
          label: "Delete",
          onClick: () => deleteProductAct(product),
        },
        {
          label: "No",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };

  useEffect(() => {
    loadProducts(CurrentPage);
  }, [appContext.search, appContext.categoryId]);

  return (
    <AdminLayout searchableType="Products">
      <div className="page">
        {IsInitialLoading && <Loading title="Loading products" />}
        {!IsInitialLoading && HasError && (
          <div>Error occured while loading products</div>
        )}
        {!IsInitialLoading && (
          <React.Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Products</h1>
                <a
                  href="#"
                  title="Refresh"
                  style={{
                    background: "rgb(213 218 226)",
                    margin: "0px 8px",
                    padding: "4px",
                    lineHeight: "18px",
                    height: "26px",
                    borderRadius: "4px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (IsReloading) {
                      return;
                    }
                    setIsSaving(true);
                    loadProducts(CurrentPage);
                  }}
                >
                  {!IsReloading && (
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <RefreshIcon />
                    </i>
                  )}
                  {IsReloading && (
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <LoadingIconSimple />
                    </i>
                  )}
                </a>


              </div>
              {userCredentials.role <= 5 && (
                <button
                  className="primary"
                  onClick={() => {
                    navigate("/products/new");
                  }}
                >
                  Create new product
                </button>
              )}
              {appContext.search && <button className="warning" style={{ display: "flex", marginLeft: "8px" }} onClick={(e) => {
                e.preventDefault();
                setAppContext({ ...appContext, search: "" });

              }}>
                Search:
                {appContext.search}{" "}
                <i className="svg-icon">
                  <CloseIcon />
                </i>
              </button>}
              <Pagination
                currentPage={CurrentPage}
                noOfItems={NoOfPages}
                onPageChange={(page: number) => {
                  setIsReloading(true);
                  setCurrentPage(page);
                  loadProducts(page);
                }}
                pageSize={PageSize}
              />
            </div>
            <CategoryFilter
              value={appContext.categoryId}
              onChange={(category: number) => {
                setAppContext(
                  { ...appContext, categoryId: category }
                );
                setCurrentPage(1);
                // loadProducts(CurrentPage, category);
              }}
            />
            <React.Fragment>
              {products.length === 0 && (
                <NotFound
                  title="No products found"
                  message="The REST products accessible by the authenticated Pega user will appear here."
                />
              )}
              <div className="grid-4-widgets">
                {products.map((product, index) => {
                  return (
                    <ProductCard
                      product={product}
                      key={index}
                      confirmDeleteProduct={confirmDeleteProduct}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </AdminLayout>
  );
};

export default Products;
