import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoBackIcon } from './Icons';

const GoBackLink = ({ level }: { level?: number }) => {
    const navigate = useNavigate();
    return (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(level ? level : -1);
        }} className="flex">
            <i className="svg-icon" style={{ fontSize: "18px", marginRight: "8px" }}>
                <GoBackIcon />
            </i>
            {" Go Back"}
        </a>
    );
};

export default GoBackLink;