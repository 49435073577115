import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";



import {
  ShopIcon,
  GoBackIcon,
  HomeIcon,
  LoadingIconSimple,
  RefreshIcon,
  TopicsIcon,
} from "../component/Icons";
import PictureWall from "../component/PictureWall/PictureWall";
import { getImageUrl, getLocation, getUniqueId, getUserType, slugify } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import { IShop } from "../interface";
import { apiEndPoint, authHeadersWithoutContentType, userCredentials } from "../api/api";
import { getEmptyShop, getShop, saveShop } from "./shopApi";
import Toggle from "../component/Toggle/Toggle";
import { shopStatus } from "./constants";
import Loading from "../component/Loading";
import MapPicker from "../component/MapPicker/MapPicker";
import GoBackLink from "../component/GoBackLink";
import AppContext from "../component/AppContext";

const Shop = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const { appContext, setAppContext } = React.useContext(AppContext);

  const isNewShop = shopId === "new";
  console.log(isNewShop, shopId);
  const [Counter, setCounter] = useState(1);
  const ShopId = isNewShop ? 0 : parseInt(shopId ? shopId : "0");
  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  let emptyShop = getEmptyShop();
  const [Shop, setShop] = useState<IShop>(emptyShop);
  const [ImageFileName, setImageFileName] = useState("");
  const [Image, setImage] = useState<Array<any>>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setShop({ ...Shop, [name]: value });
  };

  const handleChangeKeyValue = (key: string, value: string | boolean) => {
    setShop({ ...Shop, [key]: value });
  };

  const setCurrentLocation = () => {
    getLocation().then((location: any) => {
      setShop({ ...Shop, latCenter: location.coords.latitude, lonCenter: location.coords.longitude })
      setCounter(Counter + 1)
    })
  }

  const setShopLocation = (location: any) => {
    setShop({ ...Shop, latCenter: location.lat, lonCenter: location.lng })
  }

  const handleFileUpload = (file: any) => {
    return new Promise(function (resolve, rejected) {
      var formData = new FormData();
      formData.append("img", file);
      formData.append("action", "upload");
      fetch(apiEndPoint + "/v1/images/shop", {
        headers: authHeadersWithoutContentType(),
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setImageFileName(result.path);
          const mergedObject = {
            url: getImageUrl("Shop", result.path),
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            ...result.file,
          };
          resolve(mergedObject);
        })
        .catch((error) => {
          rejected("Upload fail : " + error);
        });
    });
  };

  const handleFileDelete = (uuid?: string) => {
    return new Promise(function (resolve, rejected) {
      if (uuid == "1") {
        resolve(1);
      }
      fetch(apiEndPoint + "/v1/images/shop/" + uuid, {
        headers: authHeadersWithoutContentType(),
        method: "DELETE",
      })
        .then((result) => {
          if (result.ok) {
            resolve(uuid);
          } else {
            rejected("error");
          }
        })
        .catch((error) => {
          rejected(error);
        });
    });
  };

  const loadShop = () => {
    if (IsLoading) {
      return;
    }
    if (ShopId == undefined) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getShop(ShopId, "Edit")
      .then((result: any) => {
        setShop(result);
        setImageFileName(result.logoImagePath);
        let file = {
          url: getImageUrl("Shop", result.logoImagePath),
          uuid: result.logoImagePath ? result.logoImagePath : 1
        };
        setImage([file]);
        setImageFileName(result.logoImagePath);
        setIsLoading(false)
      })
      .catch((error: any) => {
        setIsLoading(false)
        console.log("error");
      });
  };

  const handleSearch = (search: String) => {
    console.log(search);
  };

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (!formRef.current) {
      return false;
    }
    setIsSaving(true);

    saveShop(Shop)
      .then((result: any) => {
        toast.success("Shop saved successfully");
        setIsSaving(false);
        setTimeout(() => {
          navigate(`/shops/${result.id}/settings`);
        }, 2000);
      })
      .catch((err: any) => {
        setIsSaving(false);
        console.log(err);
        toast.error(err.message);
      });

    return false;
  };

  useEffect(() => {
    if (!isNewShop) {
      loadShop();
    }
    return () => { };
  }, []);

  const notify = (type: string, message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="Shop">
      <div className="page" style={{ maxWidth: "1015px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex">
            <h1>Shop Detail</h1>
            {IsSaving && (
              <i className="svg-icon" style={{ fontSize: "40px" }}>
                <LoadingIconSimple />
              </i>
            )}
          </div>
          <GoBackLink />
        </div>
        {IsLoading && <Loading title="Loading data" />}
        {!IsLoading &&
          <div className="card shadow-popover">
            <form
              ref={formRef}
              method="post"
              onSubmit={(e) => {
                handleSave(e);
              }}
            >
              <div className="body">
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Name</label>
                    </h3>
                  </div>
                  <div className="form-block">
                    <input
                      id="title"
                      type="text"
                      className="form-control"
                      required
                      value={Shop.shopName}
                      name="shopName"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="description">Description</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      This is a short summary of the shop.
                    </p>
                  </div>
                  <div className="form-block">
                    <textarea
                      id="description"
                      className="form-control"
                      value={Shop.shortDescription}
                      onChange={handleInputChange}
                      name="shortDescription"
                    // required
                    />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="description">Image</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Use a square and smaller (500x500px) image for best result.
                      Format allowed .jpg, .jpeg and .png
                    </p>
                  </div>
                  <div className="form-block">
                    <PictureWall handleUpload={handleFileUpload}
                      handleDelete={handleFileDelete} maxCount={1} notify={notify} onChange={setImage} fileList={Image} />
                  </div>
                </div>
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Delivery Methods</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Options related to delivery
                    </p>
                  </div>
                  <div className="form-block">

                    <Toggle value={Shop.homeDeliveryAvailable} label={"Home Delivery"} onChange={(value: boolean) => handleChangeKeyValue("homeDeliveryAvailable", value)} />
                    <Toggle value={Shop.pickFromShopAvailable} label={"Pickup at Store"} onChange={(value: boolean) => handleChangeKeyValue("pickFromShopAvailable", value)} />

                  </div>
                </div>
                {Shop.homeDeliveryAvailable &&
                  <div className="flex">
                    <div style={{ width: "400px" }}>
                      <h3 style={{ margin: "8px 0px" }}>
                        <label htmlFor="title">Home Delivery Charges (£)</label>
                      </h3>
                      <p style={{ margin: "0px" }} className="muted">
                        Given delivery charge will be added to the order
                        when the total order amount is less than given minimum.
                      </p>
                    </div>
                    <div className="form-block">
                      <div>
                        <p>Minimum Amount for free delivery</p>
                        <input
                          type="number"
                          name="billAmountForFreeDelivery"
                          value={Shop.billAmountForFreeDelivery}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p>Delivery charges</p>
                        <input
                          type="number"
                          name="deliveryCharges"
                          value={Shop.deliveryCharges}
                          onChange={handleInputChange}
                        />
                      </div>

                    </div>
                  </div>}


                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">
                        Helpline Numbers
                      </label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Phone number for customers to contact.
                      Whatsapp number work well.
                    </p>
                  </div>
                  <div className="form-block">
                    <div>
                      <p>General Contact</p>
                      <input
                        type="text"
                        name="customerHelplineNumber"
                        value={Shop.customerHelplineNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>Delivery Contact</p>
                      <input
                        type="text"
                        name="deliveryHelplineNumber"
                        value={Shop.deliveryHelplineNumber}
                        onChange={handleInputChange}
                      />
                    </div>

                  </div>
                </div>



                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Shop Address</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Shop address and location in the map
                    </p>
                  </div>
                  <div className="form-block">
                    <div>
                      <p>Address</p>
                      <input
                        type="text"
                        name="shopAddress"
                        value={Shop.shopAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>City</p>
                      <input
                        type="text"
                        name="city"
                        value={Shop.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>Postcode</p>
                      <input
                        type="text"
                        name="postCode"
                        value={Shop.postCode}
                        onChange={handleInputChange}
                      />
                    </div>

                  </div>
                </div>

                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Location</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Shop location map coordinates
                    </p>
                  </div>
                  <div className="form-block" style={{ flexGrow: 1 }}>
                    <div className="flex" style={{ padding: "0px" }}>
                      <div style={{ margin: "0px 8px 0px 0px" }}>
                        <label>Latitude</label><input type="text" value={Shop.latCenter} name="latCenter" onChange={handleInputChange} />
                      </div>
                      <div style={{ margin: "0px 8px 0px 0px" }}>
                        <label>Longitude</label><input type="text" value={Shop.lonCenter} name="lonCenter" onChange={handleInputChange} />
                      </div>
                      <div style={{ margin: "0px 8px 0px 0px" }}>
                        <a href="#" onClick={(e) => { e.preventDefault(); setCurrentLocation(); }}> Use Current</a>
                      </div>
                    </div>
                    <MapPicker counter={Counter} lat={Shop.latCenter ? Shop.latCenter : 0} lng={Shop.lonCenter ? Shop.lonCenter : 0} zoomNum={9} setCoordinates={setShopLocation} />
                  </div>
                </div>

                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Is Open</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      The Shop is open for business
                    </p>
                  </div>
                  <div className="form-block">
                    <Toggle value={Shop.isOpen} label={"Is Open"} onChange={(value: boolean) => handleChangeKeyValue("isOpen", value)} />
                  </div>
                </div>
                {getUserType(appContext.role) == "User" &&
                  <div className="flex">
                    <div style={{ width: "400px" }}>
                      <h3 style={{ margin: "8px 0px" }}>
                        <label htmlFor="title">Shop Access</label>
                      </h3>
                      <p style={{ margin: "0px" }} className="muted">
                        Shop admin, access
                      </p>
                    </div>
                    <div className="form-block">
                      <select>
                        <option value={shopStatus.New}>New</option>
                        <option value={shopStatus.Enabled}>Accepted</option>
                        <option value={shopStatus.Waitlisted}>Waitlist</option>
                        <option value={shopStatus.Banned}>Banned</option>
                      </select>
                      <div>
                        <p>Admin User id</p>
                        <input
                          type="text"
                          name="shopAdminID"
                          value={Shop.shopAdminID}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>

                      </div>
                      <Toggle value={Shop.itemUpdatePermitted} label={"Update Item Permitted"} onChange={(value: boolean) => handleChangeKeyValue("itemUpdatePermitted", value)} />

                    </div>
                  </div>}


                <div className="flex">
                  <div style={{ width: "400px" }}></div>
                  <button
                    className="btn btn-primary shadow-popover-sm"
                    type="submit"
                    disabled={IsSaving}
                  >
                    {IsSaving ? "Saving..." : "Save Shop"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        }
      </div >
    </AdminLayout >
  );
};

export default Shop;
