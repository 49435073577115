import { apiEndPoint, authHeaders } from "../api/api";
import { ICart, ICartItem } from "./interface";

//get all carts by username
export const getCarts = (): Promise<ICart[]> => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/Cart", {
      method: "GET",
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          reject(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCartsFromLocalStorage = (): Promise<ICart[]> => {
  return new Promise((resolve, reject) => {
    let carts = localStorage.getItem("carts");
    if (carts == null) {
      resolve([]);
    } else {
      resolve(JSON.parse(carts));
    }
  });
};

export const addItemsToCart = (item: ICartItem) => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/Cart/AddCartItem", {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify(item),
    })
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addItemsToCartLocalStorage = (item: ICartItem) => {
  return new Promise((resolve, reject) => {
    let carts = localStorage.getItem("carts");
    if (carts == null) {
      reject("No carts found");
    } else {
      let cartList = JSON.parse(carts);

      let cart = cartList.find((cart: ICart) => cart.cartId == item.cartId);
      if (cart == null) {
        reject("No cart found");
      } else {
        let cartItem = cart.cartItems.find(
          (cartItem: ICartItem) => cartItem.itemId == item.itemId
        );
        if (cartItem == null) {
          cart.cartItems.push(item);
        } else {
          cartItem.itemQuantity += item.itemQuantity;
        }
        localStorage.setItem("carts", JSON.stringify(cartList));
        resolve(cartList);
      }
    }
  });
};

export const getCart = (cartId: number): Promise<ICart> => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/Cart/GetCart", {
      method: "GET",
      headers: authHeaders(),
    })
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCartItem = (itemId: number, itemQuantity: number) => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/Cart/AddCartItem", {
      method: "POST",
      headers: authHeaders(),
      body: JSON.stringify({
        itemId: itemId,
        itemQuantity: itemQuantity,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCartItem = (itemId: number) => {
  return new Promise((resolve, reject) => {
    fetch(apiEndPoint + "/v1/Cart/DeleteCartItem/" + itemId, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCartItemLocalStorage = (cartId: string, itemId: number) => {
  return new Promise((resolve, reject) => {
    let carts = localStorage.getItem("carts");
    if (carts == null) {
      reject("No carts found");
    } else {
      let cartList = JSON.parse(carts);

      let cart = cartList.find((cart: ICart) => cart.cartId == cartId);
      if (cart == null) {
        reject("No cart found");
      } else {
        let cartItem = cart.cartItems.find(
          (cartItem: ICartItem) => cartItem.itemId == itemId
        );
        if (cartItem == null) {
          reject("No cart item found");
        } else {
          cart.cartItems.splice(cart.cartItems.indexOf(cartItem), 1);
          localStorage.setItem("carts", JSON.stringify(cartList));
          resolve(cartList);
        }
      }
    }
  });
};
