import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";

import Confirm from "../component/Confirm";
import {
  ContentIcon,
  GoBackIcon,
  LoadingIconSimple,
  RefreshIcon,
  SettingsIcon,
  TopicsIcon,
  TrashIcon,
  ViewIcon,
} from "../component/Icons";
import Loading from "../component/Loading";
import NotFound from "../component/NotFound";
import Pagination from "../component/Pagination";
import { IOrder } from "../interface";

import AdminLayout from "../layouts/AdminLayout";
import OrderCard from "./OrderCard";
import { getOrder, getOrders } from "./orderApi";

const Orders = () => {
  const navigate = useNavigate();
  const [IsSaving, setIsSaving] = useState(false);
  const [IsInitialLoading, setIsInitialLoading] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<Array<IOrder>>([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [HasError, setHasError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const PageSize = 12;
  const [NoOfPages, setNoOfPages] = useState(0);
  const [Query, setQuery] = useState("");


  const loadOrders = (pageNumber: number) => {
    setIsLoading(true);
    getOrders(pageNumber, 10, Query)
      .then((result: any) => {
        console.log(result);
        setNoOfPages(result.totalElements);
        setOrders(result.content);
        setIsInitialLoading(false);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsInitialLoading(false);
        setHasError(true);
        setErrorMessage(error.message);
        // setIsLoading(false);
      });
  };

  //delete Order
  const deleteOrder = (order: IOrder) => {
    setIsSaving(true);
  };

  const confirmDeleteOrder = (order: IOrder) => {
    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure to DELETE the order?",
      buttons: [
        {
          label: "Delete",
          onClick: () => deleteOrder(order),
        },
        {
          label: "No",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };

  useEffect(() => {
    loadOrders(CurrentPage);
  }, []);

  return (
    <AdminLayout >
      <div className="page" style={{ maxWidth: "1080px" }}>
        {IsInitialLoading && <Loading title="Loading orders" />}
        {!IsInitialLoading && HasError && (
          <NotFound title="Error" message={ErrorMessage} />
        )}
        {!IsInitialLoading && !HasError && (
          <React.Fragment>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Orders</h1>
                <a
                  href="#"
                  title="Refresh"
                  style={{
                    background: "rgb(213 218 226)",
                    margin: "0px 8px",
                    padding: "4px",
                    lineHeight: "18px",
                    height: "26px",
                    borderRadius: "4px"
                  }}

                  onClick={(e) => {
                    e.preventDefault();
                    if (IsLoading) {
                      return;
                    }
                    setIsSaving(true);
                    loadOrders(CurrentPage);

                  }}
                >
                  {!IsLoading &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <RefreshIcon />
                    </i>}
                  {IsLoading &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <LoadingIconSimple />
                    </i>}
                </a>
              </div>

              <Pagination
                currentPage={CurrentPage}
                noOfItems={NoOfPages}
                onPageChange={(page: number) => {
                  setIsLoading(true);
                  setCurrentPage(page);
                  loadOrders(page)
                }}
                pageSize={PageSize}
              />
            </div>

            <React.Fragment>
              {orders.length === 0 && (
                <NotFound
                  title="No orders found"
                  message="The list of all the applicable orders will appear here."
                />
              )}
              <div className="nav">
                <div className="list">
                  {orders.map((order, index) => {
                    return (
                      <OrderCard order={order} key={index} confirmDeleteOrder={confirmDeleteOrder} />
                    )
                  })
                  }
                </div>
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </AdminLayout >
  );
};

export default Orders;
