import { appParams } from "./component/AppContext";
import {
  CartIcon,
  CategoryIcon,
  HomeIcon,
  OrderIcon,
  ProductIcon,
  SettingsIcon,
  ShopIcon,
  SpaceIcon,
  UserIcon,
} from "./component/Icons";

export const getUniqueId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const slugify = (str: string, delimeter: string = "-") => {
  str = replaceAccents(str);
  str = str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, "");
  str = str.trim().replace(/ +/g, delimeter).toLowerCase();
  return str;
};

export const replaceAccents = (str: string) => {
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return str;
};

export const getNewSectionTemplate = () => {
  return {
    title: "New Section",
    id: "new",
    description: "Your section description goes here",
    icon: "fas fa-plus-square",
    uri: "new",
    content: { blocks: [] },
  };
};

export const getQueryParams = (s: string) => {
  if (!s || typeof s !== "string" || s.length < 2) {
    return new Map();
  }
  const a = s
    .substr(1) // remove `?`
    .split("&") // split by `&`
    .map((x) => {
      const a = x.split("=");
      return [a[0], a[1]];
    }); // split by `=`

  return a;
};

export const uploadFile = (file: string, path: string) => {
  let myProm = new Promise(function (resolve, rejected) {
    var formData = new FormData();
    formData.append("upload", file);
    formData.append("dir", path);
    formData.append("action", "upload");

    fetch("/index.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "Success") {
          resolve(result);
        } else {
          rejected(result);
        }
      })
      .catch((error) => {
        rejected("Upload fail : " + error);
      });
  });
  return myProm;
};

export const uploadByUrl = (file: string, path: string) => {
  let myProm = new Promise(function (resolve, rejected) {
    var formData = new FormData();
    formData.append("url", file);
    formData.append("dir", path);
    formData.append("action", "uploadByUrl");

    fetch("/index.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "Success") {
          resolve({ file, data: result.file });
        } else {
          rejected(result);
        }
      })
      .catch((error) => {
        rejected("Upload fail : " + error);
      });
  });
  return myProm;
};

export const handleHashClick = () => {
  // $("body").scrollTo(ev.target.hash, 500, { offset: -78, axis: "y" });
};

export const lightBox = () => {
  // ev.preventDefault();
  // $(ev.currentTarget).ekkoLightbox();
};

//save to cookie function
export const saveToCookie = (key: string, value: string) => {
  let date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  let expires = "; expires=" + date.toUTCString();
  document.cookie = key + "=" + (value || "") + expires + "; path=/";
};

//remove from cookie function
export const removeFromCookie = (key: string) => {
  document.cookie = key + "=; Max-Age=-99999999;";
};

//read from cookie function
export const readFromCookie = (key: string) => {
  let nameEQ = key + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};

export const getRelativeTime = (time: string) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  let elapsed = Date.now() - +new Date(time);

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "" + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "nearly " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "nearly " + Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const listToTree = (data: Array<any>, options: any) => {
  options = options || {};
  var ID_KEY = options.idKey || "id";
  var PARENT_KEY = options.parentKey || "parentCategoryId";
  var CHILDREN_KEY = options.childrenKey || "children";

  var map: any = {};
  for (var i = 0; i < data.length; i++) {
    // make cache
    if (data[i][ID_KEY]) {
      map[data[i][ID_KEY]] = data[i];
      data[i][CHILDREN_KEY] = [];
    }
  }
  for (var i = 0; i < data.length; i++) {
    if (data[i][PARENT_KEY]) {
      // is a child
      if (map[data[i][PARENT_KEY]]) {
        // for dirty data
        map[data[i][PARENT_KEY]][CHILDREN_KEY].push(data[i]); // add child to parent
        data.splice(i, 1); // remove from root
        i--; // iterator correction
      } else {
        data[i][PARENT_KEY] = 0; // clean dirty data
      }
    }
  }
  return data;
};

export const getImageUrl = (type: string, fileName: string | undefined) => {
  if (fileName == "" || fileName == null || fileName == undefined) {
    if (type == "Shop") {
      return "https://assets.indianstores.co.uk/img/placeholder-shop.png";
    } else {
      return "https://assets.indianstores.co.uk/img/placeholder.png";
    }
  }
  if (type == "Category") {
    return (
      "https://static.indianstores.co.uk/images/ItemCategory/three_hundred_" +
      fileName +
      ".jpg"
    );
  } else if (type == "Item" || type == "Product") {
    return (
      "https://static.indianstores.co.uk/images/Item/three_hundred_" +
      fileName +
      ".jpg"
    );
  } else if (type == "Shop") {
    return (
      "https://static.indianstores.co.uk/images/Shop/three_hundred_" +
      fileName +
      ".jpg"
    );
  } else if (type == "User") {
    return (
      "https://static.indianstores.co.uk/images/User/three_hundred_" +
      fileName +
      ".jpg"
    );
  }
  return "";
};

export const getLocation = () => {
  return new Promise((resolve: Function, reject: Function) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position);
      });
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  });
};

export const userRoleMapping = [
  {
    role: 1,
    name: "Admin",
  },
  {
    role: 2,
    name: "Admin Staff",
  },
  {
    role: 3,
    name: "Common Delivery",
  },
  {
    role: 4,
    name: "Shop Admin",
  },
  {
    role: 5,
    name: "Shop Staff",
  },
  {
    role: 6,
    name: "Shop Delivery",
  },
  {
    role: 7,
    name: "End User",
  },
];

export const getUserRoleName = (roleId: number) => {
  return userRoleMapping.find((mapping) => {
    return mapping.role == roleId;
  })?.name;
};

export const getUserType = (roleId: number) => {
  if (roleId == 1 || roleId == 2) {
    return "Admin";
  } else if (roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6) {
    return "Shop";
  } else if (roleId == 7) {
    return "User";
  }
  return "";
};

export const getSideBarMenu = (appContext: appParams) => {
  const roleId = appContext.role;
  const shopId = appContext.shopID ? appContext.shopID : "new/settings";
  console.log("shopId", shopId, appContext);
  if (roleId == 1 || roleId == 2) {
    return [
      {
        title: "Home",
        icon: HomeIcon,
        path: "/",
        isExactMatch: true,
      },
      {
        title: "Categories",
        icon: CategoryIcon,
        path: "/categories",
      },
      {
        title: "Products",
        icon: ProductIcon,
        path: "/products",
      },
      {
        title: "Shops",
        icon: ShopIcon,
        path: "/shops",
      },
      {
        title: "Users",
        icon: UserIcon,
        path: "/users",
      },
      {
        title: "Orders",
        icon: OrderIcon,
        path: "/orders",
      },
      {
        title: "Settings",
        icon: SettingsIcon,
        path: "/settings",
      },
    ];
  } else if (roleId == 3 || roleId == 4 || roleId == 5 || roleId == 6) {
    return [
      {
        title: "Home",
        icon: HomeIcon,
        path: "/",
        isExactMatch: true,
      },

      {
        title: "Products",
        icon: ProductIcon,
        path: "/products",
      },
      {
        title: "Shops",
        icon: ShopIcon,
        path: "/shops/" + shopId,
      },
      {
        title: "Orders",
        icon: OrderIcon,
        path: "/orders",
      },
      {
        title: "Settings",
        icon: SettingsIcon,
        path: "/settings",
      },
    ];
  }
  return [
    {
      title: "Home",
      icon: HomeIcon,
      path: "/",
      isExactMatch: true,
    },
    {
      title: "Products",
      icon: ProductIcon,
      path: "/products",
    },
    {
      title: "Shops",
      icon: ShopIcon,
      path: "/shops",
    },
    {
      title: "Orders",
      icon: OrderIcon,
      path: "/orders",
    },
    {
      title: "Carts",
      icon: CartIcon,
      path: "/carts",
    },
    {
      title: "Settings",
      icon: SettingsIcon,
      path: "/settings",
    },
  ];
};

export const getDeviceId = () => {
  //if available in local storage
  let deviceId = localStorage.getItem("deviceId");
  if (deviceId != null) {
    return deviceId;
  }

  var nav = window.navigator;
  var screen = window.screen;
  var date = new Date();
  var seed = "2023-02-10";

  var uniqueString =
    seed +
    nav.userAgent +
    nav.language +
    nav.platform +
    screen.width +
    screen.height +
    date.getTime();

  // Add number of CPU cores
  if (navigator.hardwareConcurrency) {
    uniqueString += navigator.hardwareConcurrency;
  }

  deviceId = btoa(uniqueString).substring(0, 30);

  //store in local storage
  localStorage.setItem("deviceId", deviceId);

  return deviceId;
};

export const deleteDeviceId = () => {
  localStorage.clear();
};

export const getDeviceName = () => {
  const userAgent = navigator.userAgent;
  let deviceName = "";
  let browserName = "";

  if (userAgent.indexOf("Windows") !== -1) {
    deviceName = "Windows";
  } else if (userAgent.indexOf("Mac") !== -1) {
    deviceName = "Mac";
  } else if (userAgent.indexOf("Linux") !== -1) {
    deviceName = "Linux";
  } else if (userAgent.indexOf("Android") !== -1) {
    deviceName = "Android";
  } else if (userAgent.indexOf("iOS") !== -1) {
    deviceName = "iOS";
  } else {
    deviceName = "Unknown device";
  }

  if (userAgent.indexOf("Chrome") !== -1) {
    browserName = "Chrome";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    browserName = "Firefox";
  } else if (userAgent.indexOf("Safari") !== -1) {
    browserName = "Safari";
  } else if (userAgent.indexOf("Edge") !== -1) {
    browserName = "Edge";
  } else if (
    userAgent.indexOf("Opera") !== -1 ||
    userAgent.indexOf("OPR") !== -1
  ) {
    browserName = "Opera";
  } else if (userAgent.indexOf("Trident") !== -1) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown browser";
  }

  return deviceName + " " + browserName;
};
