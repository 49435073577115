import React from 'react';
import Overview from '../component/Overview';

import { getProductCountEndPoint, getShopsEndPoint, getUserCountEndPoint } from "../api/api";
import { ProductIcon, ShopIcon, SpaceIcon, UserIcon } from "../component/Icons";
import { useNavigate } from 'react-router-dom';

const getCount = (data: any) => {
    return data.count;
};

const GuestHome = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="overview">
                <Overview
                    dataSource={getShopsEndPoint}
                    icon={ShopIcon}
                    title="Shops"
                    isAuthenticated={true}
                    getValueFromData={getCount}
                    onClick={() => {
                        navigate("shops");
                    }} />
                <Overview
                    dataSource={getProductCountEndPoint}
                    icon={ProductIcon}
                    title="Products"
                    isAuthenticated={true}
                    getValueFromData={getCount}
                    onClick={() => {
                        navigate("products");
                    }} />
                <Overview
                    dataSource={getUserCountEndPoint}
                    icon={UserIcon}
                    title="Users"
                    isAuthenticated={true}
                    getValueFromData={getCount}
                    onClick={() => {
                        navigate("users");
                    }} />
            </div>
        </>
    );
};

export default GuestHome;