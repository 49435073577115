import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { LoaderIcon, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";

import { LoadingIconSimple, TopicsIcon } from "../component/Icons";
import PictureWall from "../component/PictureWall/PictureWall";
import { getImageUrl } from "../helper";
import AdminLayout from "../layouts/AdminLayout";
import { IOrder, Item, ItemCategory } from "../interface";
import {
  apiEndPoint,
  authHeadersWithoutContentType,
} from "../api/api";
import { getEmptyOrder, getOrder, saveOrder } from "./orderApi";
import Toggle from "../component/Toggle/Toggle";
import Loading from "../component/Loading";
import MapPicker from "../component/MapPicker/MapPicker";
import { categorieStore } from "../Category/categoryStore";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import CategoryPicker from "../component/CategoryPicker/CategoryPicker";
import AutoComplete from "../component/AutoComplete/AutoComplete";
import { getCategories } from "../Category/categoriesApi";
import GoBackLink from "../component/GoBackLink";

const Order = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const isNewOrder = orderId === undefined;
  const OrderId = orderId != undefined ? parseInt(orderId) : undefined;
  // const [OrderCategoryId, setOrderCategoryId] = useState(0);
  const [IsAllCategoriesLoaded, setIsAllCategoriesLoaded] = useState(
    categorieStore.isLoaded
  );
  const [IsSaving, setIsSaving] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  let emptyOrder = getEmptyOrder();
  const [Order, setOrder] = useState<IOrder>(emptyOrder);
  const [ImageFileName, setImageFileName] = useState("");
  const [Image, setImage] = useState<Array<any>>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setOrder({ ...Order, [name]: value });
  };

  const handleChangeKeyValue = (key: string, value: string | boolean) => {
    setOrder({ ...Order, [key]: value });
  };

  const handleFileUpload = (file: any) => {
    return new Promise(function (resolve, rejected) {
      var formData = new FormData();
      formData.append("img", file);
      formData.append("action", "upload");
      fetch(apiEndPoint + "/v1/images/order", {
        headers: authHeadersWithoutContentType(),
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setImageFileName(result.path);
          const mergedObject = {
            url: getImageUrl("Order", result.path),
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            ...result.file,
          };
          resolve(mergedObject);
        })
        .catch((error) => {
          rejected("Upload fail : " + error);
        });
    });
  };

  const handleFileDelete = (uuid?: string) => {
    return new Promise(function (resolve, rejected) {
      if (uuid == "1") {
        resolve(1);
      }
      fetch(apiEndPoint + "/v1/images/order/" + uuid, {
        headers: authHeadersWithoutContentType(),
        method: "DELETE",
      })
        .then((result) => {
          if (result.ok) {
            resolve(uuid);
          } else {
            rejected("error");
          }
        })
        .catch((error) => {
          rejected(error);
        });
    });
  };

  const loadOrder = () => {
    if (IsLoading) {
      return;
    }
    if (OrderId == undefined) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    loadCategories();

    getOrder(OrderId)
      .then((result: any) => {
        setOrder(result);
        setImageFileName(result.itemImageUrl);
        let file = {
          url: getImageUrl("Order", result.itemImageUrl),
          uuid: result.itemImageUrl ? result.itemImageUrl : 1,
        };
        setImage([file]);
        setImageFileName(result.itemImageUrl);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log("error");
      });
  };

  const loadCategories = () => {
    if (IsAllCategoriesLoaded) {
      return;
    }
    getCategories()
      .then((result: any) => {
        categorieStore.categories = result.content.map(
          (category: ItemCategory) => {
            category.imagePath = getImageUrl("Category", category.imagePath);
            return category;
          }
        );
        categorieStore.isLoaded = true;
        setIsAllCategoriesLoaded(true);
      })
      .catch((error: any) => {
        console.log("error");
      });
  };


  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    if (!formRef.current) {
      return false;
    }
    setIsSaving(true);

    saveOrder(Order)
      .then((result: any) => {
        toast.success("Order saved successfully");
        setIsSaving(false);
        console.log(result);
        setTimeout(() => {
          console.log(result);
          navigate(`/orders/${result.id}/settings`);
        }, 2000);
      })
      .catch((err: any) => {
        setIsSaving(false);
        console.log(err);
        toast.error(err.message);
      });

    return false;
  };

  useEffect(() => {
    if (!isNewOrder) {
      loadOrder();
    }
    return () => { };
  }, []);

  const notify = (type: string, message: string) => {
    toast.error(message);
  };

  return (
    <AdminLayout searchableType="Order">
      <div className="page" style={{ maxWidth: "1015px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex">
            <h1>Order Detail</h1>
            {IsSaving && (
              <i className="svg-icon" style={{ fontSize: "40px" }}>
                <LoadingIconSimple />
              </i>
            )}
          </div>
          <GoBackLink />
        </div>
        {IsLoading && <Loading title="Loading data" />}
        {!IsLoading && (
          <div className="card shadow-popover">
            <form
              ref={formRef}
              method="post"
              onSubmit={(e) => {
                handleSave(e);
              }}
            >
              <div className="body">
                <div className="flex">
                  <div style={{ width: "400px" }}>
                    <h3 style={{ margin: "8px 0px" }}>
                      <label htmlFor="title">Name</label>
                    </h3>
                    <p style={{ margin: "0px" }} className="muted">
                      Descriptive name for the order
                    </p>
                  </div>
                  <div className="form-block">
                    <input
                      id="title"
                      type="text"
                      className="form-control"
                      required
                      // value={Order.name}
                      name="name"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>


              </div>

              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="description">Description</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    This is a short summary of the order.
                  </p>
                </div>
                <div className="form-block" style={{ flexGrow: 1 }}>
                  <p style={{ color: "#ccc", margin: "8px 0px 4px 0px" }}>
                    Short Description
                  </p>
                  <textarea
                    id="description"
                    className="form-control"
                    // value={Order.itemDesc}
                    onChange={handleInputChange}
                    name="itemDesc"
                    style={{ height: "80px" }}
                  />
                  <p style={{ color: "#ccc", margin: "24px 0px 4px 0px" }}>
                    Long Description
                  </p>
                  <textarea
                    id="description"
                    className="form-control"
                    // value={Order.itemDescriptionLong}
                    onChange={handleInputChange}
                    name="itemDescriptionLong"
                    style={{ height: "150px" }}
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="description">Image</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    Prefer a square (approx 500x500px) image. Format allowed
                    .jpg, .jpeg and .png
                  </p>
                </div>
                <div className="form-block">
                  <PictureWall
                    handleUpload={handleFileUpload}
                    handleDelete={handleFileDelete}
                    maxCount={1}
                    notify={notify}
                    onChange={setImage}
                    fileList={Image}
                  />
                </div>
              </div>

              <div className="flex">
                <div style={{ width: "400px" }}>
                  <h3 style={{ margin: "8px 0px" }}>
                    <label htmlFor="title">Price (£)</label>
                  </h3>
                  <p style={{ margin: "0px" }} className="muted">
                    Price and unit of measure
                  </p>
                </div>
                <div className="form-block">
                  <div>
                    <p>List Price</p>
                    <input
                      type="number"
                      name="listPrice"
                      // value={Order.listPrice}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <p>Unit of Measure</p>
                    {/* <AutoComplete
                        suggestions={[
                          "Bag",
                          "Box",
                          "Each",
                          "Dozen",
                          "Gram",
                          "Inch",
                          "Kilogram",
                          "Liter",
                          "Millimeter",
                          "Meter",
                          "Piece",
                          "Pound",
                          "Unit",
                          "Sheet",
                          "Yard"
                        ]}
                        handleChange={(value: string) => {
                          handleChangeKeyValue("quantityUnit", value);
                        }}
                        defaultValue={Order.id}
                      /> */}
                    {/* <input
                        type="text"
                        name="quantityUnit"
                        value={Order.quantityUnit}
                        onChange={handleInputChange}
                      /> */}
                  </div>
                </div>
              </div>

              <div className="flex">
                <div style={{ width: "400px" }}></div>
                <button
                  className="btn btn-primary shadow-popover-sm"
                  type="submit"
                  disabled={IsSaving}
                >
                  {IsSaving ? "Saving..." : "Save Order"}
                </button>
              </div>

            </form>
          </div>
        )
        }
      </div >
    </AdminLayout >
  );
};

export default Order;
