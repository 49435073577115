import React from "react";
import { Link } from "react-router-dom";
import { ItemCategory } from "../../interface";
import "./breadcrumb.css"

const Breadcrumb = ({ items, rootId, currentId, onSelect }: { items: Array<any>, rootId: number, currentId?: number, onSelect: Function }) => {
    const elementList = [];
    let counter = 0;
    while (currentId != null && counter < 5) {
        counter++;
        let item = items.find((item: ItemCategory) => {
            return item.id == currentId
        })
        if (item != undefined) {
            currentId = item.parentCategoryId
            elementList.unshift(
                item
            )
        }
    }

    return (
        <ul className="breadcrumb">
            {elementList.map((item, index) => {
                return <li key={index}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        onSelect(item.id);
                    }}>
                        {item.name}
                    </a>

                </li>;
            })}
        </ul>
    );
};

export default Breadcrumb;
items: Array<{
    title: string;
    link: string;
    imageUrl: string;
}>;
