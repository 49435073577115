import React, { ReactNode, useEffect, useState } from "react";

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children: ReactNode,
    refreshCounter2: number
}

const Map = ({ onClick, onIdle, children, center, style, refreshCounter2, ...options }: MapProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options));
        }
        if (center)
            map?.setCenter(center);

    }, [ref, map, center, refreshCounter2]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) => {
                google.maps.event.clearListeners(map, eventName);
            }
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);


    return (<>
        <div ref={ref} style={{ height: "500px" }}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child as React.ReactElement, { map });
                }
            })}
        </div>
    </>)
};

export default Map;

