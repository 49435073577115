import React, { useState, useEffect, useContext } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { logout, userCredentials } from "../api/api";
// import { fetchJSONData, getConfigEndPoint, getLogoutEndPoint } from "../api/api";
import {
  deleteDeviceId,
  getUserRoleName,
  removeFromCookie,
  saveToCookie,
} from "../helper";
import { IUser } from "../interface";
import {
  LoadingIconSimple,
  ExpandIcon,
  UserIcon,
  DarkModeIcon,
  LightModeIcon,
  AddIcon,
  LogoutIcon,
  SearchIcon,
} from "./Icons";
import Menu from "./DropdownMenu/Menu";
import { getUser } from "../User/userApi";
import SearchContext from "./AppContext";
import AppContext from "./AppContext";

const Header = ({ searchableType }: { searchableType?: String }) => {
  const navigate = useNavigate();
  const [User, setAppConfig] = useState<IUser>();
  const { appContext, setAppContext } = useContext(AppContext);
  const [SearchText, setSearchText] = useState(appContext.search);

  const handleLogout = () => {
    removeFromCookie("istorecore");
    deleteDeviceId();
    logout()
      .then((result) => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  };

  const menuOptions = [];
  if (appContext.isLoggedIn) {
    menuOptions.push([
      <Link
        to={"/users/" + appContext.userID}
        style={{ display: "flex", alignItems: "center" }}
        key={1}
      >
        {appContext.profile !== "" && (
          <img
            src={appContext.profile}
            alt="profile"
            style={{ height: "50px", width: "50px", marginRight: "16px" }}
          />
        )}
        {appContext.profile == "" && (
          <img
            src="https://picsum.photos/200"
            alt="profile"
            style={{ height: "50px", width: "50px", marginRight: "16px" }}
          />
        )}
        <span className="muted">
          {appContext.name} <br />({getUserRoleName(appContext.role)})
        </span>
      </Link>,
      <Link to={"/settings"} key={2}>Settings</Link>,
      <Link to={"/login"} key={3} onClick={handleLogout}>
        Logout
      </Link>,
    ]);
  } else {
    menuOptions.push(
      <Link to={"/login"} key={4} onClick={handleLogout}>
        Login
      </Link>
    );
  }

  return (
    <header
      className={"header" + (appContext.isInDarkMode ? " theme-dark" : "")}
    >
      <div style={{ display: "flex" }}>
        <h2 id="expand-icon">
          <a
            href="#"
            onClick={(e) => {
              {
                setAppContext({
                  ...appContext,
                  isSideBarOpen: !appContext.isSideBarOpen,
                });
              }
            }}
          >
            <i className="svg-icon expand" style={{ display: "flex" }}>
              <ExpandIcon />
            </i>
          </a>
        </h2>
        <h2
          id="logoText"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        >
          <span style={{ color: "rgb(255 184 109)" }}>Indian</span>
          <span style={{ color: "rgb(13 138 4)" }}>Stores</span>.co.uk
        </h2>
        <div id="logoImg">
          <img src={"/images/logo.png"} style={{ height: "100%" }} alt="logo" />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="search-box flex" style={{ margin: "0px 16px" }}>
          <input
            className="form-control"
            id="searchStr"
            placeholder={
              "Search " +
              (searchableType != undefined ? searchableType : " Shops")
            }
            value={SearchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                setAppContext({
                  ...appContext,
                  search: SearchText,
                });
              }
            }}
            style={{
              margin: "0px",
              height: "16px",
              borderRadius: "4px 0px 0px 4px",
            }}
          />
          <button
            className="btn"
            type="button"
            onClick={(e) => {
              setAppContext({
                ...appContext,
                search: SearchText,
              });
            }}
            style={{
              height: "38px",
              borderRadius: "0px 4px 4px 0px",
              color: "#fff",
            }}
          >
            <i className="svg-icon">
              <SearchIcon />
            </i>
          </button>
        </div>

        <div
          className={"svg-icon" + (appContext.isInDarkMode ? " yellow" : "")}
          style={{
            display: "inline-block",
            height: "18px",
            fontSize: "18px",
            color: "#6d6d6d",
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              setAppContext({
                ...appContext,
                isInDarkMode: !appContext.isInDarkMode,
              });
            }}
            title="Dark mode switch"
            style={{ display: "inline-block" }}
          >
            {!appContext.isInDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
          </a>
        </div>

        <Menu
          label={
            <>
              {" "}
              {userCredentials.profile !== "" && (
                <img
                  src={userCredentials.profile}
                  alt="profile"
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    border: "2px double red",
                  }}
                />
              )}
              {userCredentials.profile === "" && (
                <img
                  src="https://picsum.photos/200"
                  alt="profile"
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    border: "2px double red",
                  }}
                />
              )}
            </>
          }
          options={menuOptions}
        />
      </div>
    </header>
  );
};

export default Header;
