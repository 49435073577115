import React from "react";

import "./toggle.scss";

const Toggle = ({
    value,
    onChange,
    label,
}: {
    value: boolean;
    label: string;
    onChange: Function;
}) => {
    return (
        <div className="toggle">
            <label className="switch">
                <input
                    type="checkbox"
                    name="s"
                    checked={value}
                    onChange={(e) => {
                        onChange(!value);
                    }}
                />
                <div className="slider"></div>
            </label>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(!value);
                }}
            >
                {label}
            </a>
        </div>
    );
};

export default Toggle;
