import React from "react";
import { useNavigate } from "react-router-dom";

import AdminLayout from "../layouts/AdminLayout";
import AppContext from "../component/AppContext";
import { getUserType } from "../helper";
import AdminHome from "./AdminHome";
import ShopHome from "./ShopHome";
import UserHome from "./UserHome";
import GuestHome from "./GuestHome";




const Home = () => {
  const { appContext, setAppContext } = React.useContext(AppContext);
  const userType = getUserType(appContext.role);
  const HomePage = userType == 'Admin' ? AdminHome : userType == 'Shop' ? ShopHome : UserHome;
  return (
    <AdminLayout >
      {appContext.isLoggedIn && <HomePage />}
      {!appContext.isLoggedIn && <GuestHome />}
    </AdminLayout>
  );
};

export default Home;
