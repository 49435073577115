import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  signup,

  userCredentials,
  verifyLoginUsingOtp,
} from "../api/api";
import { getDeviceId, saveToCookie } from "../helper";
import FullScreenLayout from "../layouts/FullScreenLayout";
import { HelpIcon } from "../component/Icons";
import { toast } from "react-hot-toast";
import { SendVerificationCode } from "../User/userApi";
import AppContext from "../component/AppContext";

const SignupPage = () => {
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [IsPhoneMode, setIsPhoneMode] = useState(true);
  const [IsCustomer, setIsCustomer] = useState(true);
  const [IsOTPSent, setIsOTPSent] = useState(false);
  const [Name, setName] = useState("");
  const [ShopName, setShopName] = useState("");

  const { appContext, setAppContext } = useContext(AppContext);

  const [isWaiting, setIsWaiting] = useState(false);
  const [HasLoginError, setHasLoginError] = useState(false);
  const [LoginErrorMessage, setLoginErrorMessage] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return userCredentials.token != "";
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == "phone") {
      setPhoneNumber(e.target.value);
    } else if (e.target.name == "email") {
      setEmail(e.target.value);
    }
  };

  const confirmVerificationCode = () => {
    setIsWaiting(true);
    let regMode = IsPhoneMode ? 2 : 1;
    let regUsername = IsPhoneMode ? PhoneNumber : Email;

    signup(
      regMode,
      Name,
      PhoneNumber,
      Email,
      IsCustomer ? "" : "vendor",
      ShopName,
      OTP
    ).then((result: any) => {

      userCredentials.username = getDeviceId();
      userCredentials.token = result.token;

      setAppContext({ ...appContext, userID: result.id, name: result.name, isLoggedIn: true, role: result.role, profile: result.profileImageUrl });
      saveToCookie(
        "istorecore",
        JSON.stringify({
          username: userCredentials.username,
          token: userCredentials.token,
        })
      );

      navigate("/");
    }).catch((error) => {
      setIsWaiting(false);
      setHasLoginError(true);
      setLoginErrorMessage(error);
    });

  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setHasLoginError(false);
    if (IsOTPSent) {
      confirmVerificationCode();
    } else {

      sendVerificationCode();

    }
  };


  //send verification code
  const sendVerificationCode = () => {
    setIsWaiting(true);
    let regMode: "phone" | "email" = IsPhoneMode ? "phone" : "email";
    let regUsername = IsPhoneMode ? PhoneNumber : Email;

    SendVerificationCode(regMode, regUsername)
      .then((result) => {
        setIsOTPSent(true);
        setIsWaiting(false);
      })
      .catch((error) => {
        setIsWaiting(false);
        setLoginErrorMessage("Error: " + error);
        setHasLoginError(true);
      });
  };

  const Signup = () => {
    setIsWaiting(true);
    signup(
      IsPhoneMode ? 2 : 1,
      Name,
      PhoneNumber,
      Email,
      IsCustomer ? "" : "vendor",
      ShopName,
      OTP
    ).
      then((result: any) => {
        setIsOTPSent(true);
        setIsWaiting(false);
      }
      ).catch((error) => {
        setIsWaiting(false);
        setHasLoginError(true);
        setLoginErrorMessage("Error: " + error);
      });
  };

  useEffect(() => {
    isLoggedIn();
    return () => { };
  }, []);

  return (
    <FullScreenLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
        className="app login"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(208deg 75% 95%)",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              opacity: 1,
              width: "300px",
              background: "rgb(255 255 255)",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "rgb(204 204 204) 0px 4px 12px",
              justifyContent: "center",
            }}
          >
            <img src="/images/logo-medium.png" style={{ width: "100%" }} />
            <div className="reg-form">
              <button
                className="active"
                style={{
                  marginRight: "-8px",
                  padding: "8px 16px",
                  borderRadius: "16px",
                  opacity: "1",
                }}


              >
                Sign Up
              </button>
              <button
                className=""
                style={{
                  marginRight: "-8px",
                  padding: "8px 16px",
                  borderRadius: "16px",
                  opacity: "1",
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Sign In
              </button>
            </div>
            <form>
              {HasLoginError && (
                <div
                  style={{
                    background: "#d23752",
                    padding: "8px",
                    margin: "16px 0px",
                    color: "#fff",
                  }}
                >
                  {LoginErrorMessage}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  paddingRight: "16px",
                  marginBottom: "8px",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}

              >
                <label style={{ marginRight: "16px" }}>
                  <input
                    type="radio"
                    name="type"
                    checked={IsCustomer}
                    onChange={(e) => {
                      setIsCustomer(true);
                    }}
                  />{" "}
                  Customer
                </label>
                <label>
                  <input
                    type="radio"
                    value="customer"
                    checked={!IsCustomer}
                    name="type"
                    onChange={(e) => {
                      setIsCustomer(false);
                    }}
                  />{" "}
                  Seller
                </label>
                <i className="svg-icon" title="Choose vendor if you are shop owner"><HelpIcon /></i>

              </div>


              <div style={{ display: "block", paddingRight: "16px" }}>
                <label htmlFor="email">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>

              {!IsCustomer && (
                <div style={{ display: "block", paddingRight: "16px" }}>
                  <label htmlFor="email">Shop Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="shopname"
                    placeholder="Shop Name"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setShopName(e.target.value);
                    }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  paddingRight: "16px",
                  margin: "16px 0px 8px 0px",
                }}
              >
                <label style={{ marginRight: "16px" }}>
                  <input
                    type="radio"
                    name="mode"
                    checked={IsPhoneMode}
                    onChange={(e) => {
                      setIsPhoneMode(true);
                      setIsOTPSent(false);
                    }}
                  />{" "}
                  Phone
                </label>
                <label>
                  <input
                    type="radio"
                    value="email"
                    checked={!IsPhoneMode}
                    name="mode"
                    onChange={(e) => {
                      setIsPhoneMode(false);
                      setIsOTPSent(false);
                    }}
                  />{" "}
                  Email
                </label>
              </div>
              {!IsPhoneMode && (
                <div style={{ display: "block", paddingRight: "16px" }}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              {IsPhoneMode && (
                <div style={{ display: "block", paddingRight: "16px" }}>
                  <label htmlFor="phone">Mobile Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="07XXXXXXX"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              )}
              <br />
              {IsOTPSent && (
                <div style={{ display: "block", paddingRight: "16px" }}>
                  <label htmlFor="otp">Verification Code</label>
                  <input
                    type="otp"
                    className="form-control"
                    id="otp"
                    placeholder="- - - - - -"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setOTP(e.target.value);
                    }}
                  />
                </div>
              )}
              <div>
                {!IsOTPSent && (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    disabled={isWaiting}
                  >
                    {isWaiting && <div>Waiting...</div>}
                    {!isWaiting && <div>Send Verification Code</div>}
                  </button>
                )}
                {IsOTPSent && (
                  <button
                    type="button"
                    onClick={confirmVerificationCode}
                    className="btn btn-primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    disabled={isWaiting}
                  >
                    {isWaiting && <div>Waiting...</div>}
                    {!isWaiting && <div>Confirm Code</div>}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </FullScreenLayout>
  );
};

export default SignupPage;
props: {
}
