import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate, useParams, useRoutes, useSearchParams } from "react-router-dom";

import { categorieStore } from "../Category/categoryStore";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import Confirm from "../component/Confirm";
import {
  AddIcon,
  LoadingIconSimple,
  RefreshIcon,
} from "../component/Icons";
import Loading from "../component/Loading";
import NotFound from "../component/NotFound";
import Pagination from "../component/Pagination";
import { IShop, Item } from "../interface";

import AdminLayout from "../layouts/AdminLayout";
import { toast } from "react-hot-toast";
import { deleteShop, getProductsByShop, getShops } from "./shopApi";
import ShopCard from "./ShopCard";

const Shops = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [IsSaving, setIsSaving] = useState(false);
  const [IsInitialLoading, setIsInitialLoading] = useState(true);
  const [IsLoading, setIsLoading] = useState(true);
  const [CurrentShops, setCurrentShops] = useState<Array<IShop>>([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [HasError, setHasError] = useState(false);
  const PageSize = 50;

  const loadShops = () => {
    setIsLoading(true);
    getShops(CurrentPage, PageSize, query)
      .then((result: any) => {
        setCurrentShops(result.content);
        setIsLoading(false);
        setIsInitialLoading(false);
      })
      .catch((error: any) => {
        toast.error(error);
        setIsInitialLoading(false);
        setIsLoading(false);
      });
  };


  //delete 
  const handleDelete = (shop: IShop) => {
    setIsSaving(true);
    deleteShop(shop).then((data: any) => {
      toast.success("Shop has been deleted successfully");
      setIsSaving(false);
      loadShops();
    }).catch((err) => {
      toast.error(err);
      console.log(err);
      setIsSaving(false);
    });
  };

  const confirmDeleteShop = (shop: IShop) => {
    confirmAlert({
      title: "Confirm delete",
      message: "Are you sure to DELETE the shop?",
      buttons: [
        {
          label: "Delete",
          onClick: () => handleDelete(shop),
        },
        {
          label: "No",
          onClick: () => console.log("Cancelled"),
        },
      ],
    });
  };


  useEffect(() => {
    loadShops();
    return () => {
    }
  }, [CurrentPage, query]);



  return (
    <AdminLayout >
      <div className="page">
        {IsInitialLoading && <Loading title="Loading data..." />}
        {!IsInitialLoading && HasError && (
          <div>Error occured while loading shops</div>
        )}
        {!IsInitialLoading && (
          <React.Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between " }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Shops</h1>
                <a
                  href="#"
                  title="Refresh"
                  style={{
                    background: "rgb(213 218 226)",
                    margin: "0px 8px",
                    padding: "4px",
                    lineHeight: "18px",
                    height: "26px",
                    borderRadius: "4px"
                  }}

                  onClick={(e) => {
                    e.preventDefault();
                    if (IsLoading) {
                      return;
                    }
                    loadShops();
                  }}
                >
                  {!IsSaving &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <RefreshIcon />
                    </i>}
                  {IsSaving &&
                    <i className="svg-icon" style={{ fontSize: "24px" }}>
                      <LoadingIconSimple />
                    </i>}
                </a>


              </div>
              <div>
                {(CurrentShops.length > 0) &&
                  <button
                    className="primary"
                    onClick={() => {
                      navigate("/shops/new/settings");
                    }}
                    style={{ marginLeft: "8px" }}
                  >
                    <i className="svg-icon">
                      <AddIcon />
                    </i>
                    Create New Shop
                  </button>}
              </div>
            </div>

            <React.Fragment>
              {CurrentShops.length === 0 && (
                <NotFound
                  title="No shops found"
                  message="Shops will appear here."
                />
              )}
              <div className="grid-6-widgets">
                {CurrentShops.map((shop, index) => {
                  if (
                    index >= (CurrentPage - 1) * PageSize &&
                    index < CurrentPage * PageSize
                  ) {
                    return (
                      <ShopCard shop={shop} key={index} confirmDeleteShop={confirmDeleteShop} />
                    );
                  }
                })}
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </AdminLayout>
  );
};

export default Shops;
